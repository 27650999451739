import React, { useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { Button, Img, Input, Line, List, SelectBox, Text } from "components";

import { CloseSVG } from "../../assets/images";
import { ICustomer } from "interfaces/customer";
import { ApiProvider } from "apiProvider/api_provider";
import { EmiStatus, IChit, ICustomerChit } from "interfaces/chit_interfaces";
import { IMFPayment, IMicroFinance } from "interfaces/micro_finance_interfaces";
import { IGeneralFinance } from "interfaces/general_finance_interfaces";
import { Constants, IDropdown } from "constants/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FinanceType, IFinance, IPayment } from "interfaces/payment_interfaces";
import { IGlobalProps } from "Routes";
import { Permission, PermissionClass } from "interfaces/user_interfaces";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import numWords from "num-words";

const Payments = (props: IGlobalProps) => {
  const pdfRef = useRef();
  const [refresh, setRefresh] = React.useState(true);
  const [viewPopup, setViewPopup] = React.useState(false);
  const [addPopup, setAddPopup] = React.useState(false);

  const [totalAmount, setTotalAmount] = React.useState<string>("");
  const [remarks, setRemarks] = React.useState<string>("");

  const [selectedCustomer, setSelectedCustomer] = React.useState<string>(null);
  const [customerChits, setCustomerChits] = React.useState<IChit[]>([]);
  const [customerMicroFinances, setCustomerMicroFinances] = React.useState<
    IMicroFinance[]
  >([]);
  const [customerGeneralFinances, setCustomerGeneralFinances] = React.useState<
    IGeneralFinance[]
  >([]);

  const [chitAmountsHoldingList, setChitAmountsHoldingList] = React.useState<
    string[]
  >([]);
  const [microfinanceAmountsHoldingList, setMicrofinanceAmountsHoldingList] =
    React.useState<string[]>([]);
  const [
    generalfinanceAmountsHoldingList,
    setGeneralfinanceAmountsHoldingList,
  ] = React.useState<string[]>([]);

  const [chitDropdownList, setChitDropdownList] = React.useState<
    Array<IDropdown[]>
  >([]);
  const [microfinanceDropdownList, setMicrofinanceDropdownList] =
    React.useState<Array<IDropdown[]>>([]);
  const [generalfinanceDropdownList, setGeneralfinanceDropdownList] =
    React.useState<Array<IDropdown[]>>([]);

  const [chitSelectedDropdownList, setChitSelectedDropdownList] =
    React.useState<string[]>([]);
  const [
    microfinanceSelectedDropdownList,
    setMicrofinanceSelectedDropdownList,
  ] = React.useState<string[]>([]);
  const [
    generalfinanceSelectedDropdownList,
    setGeneralfinanceSelectedDropdownList,
  ] = React.useState<string[]>([]);

  const [customerList, setCustomerList] = React.useState<ICustomer[]>(null);
  const [dropdownCustomerList, setDropdownCustomerList] = React.useState([]);

  const [payments, setPayments] = React.useState<IPayment[]>([]);
  const [page, setPage] = React.useState<number>(1);

  const [totalCount, setTotalCount] = React.useState<number>(null);

  const [selectedPayment, setSelectedPayment] = React.useState<IPayment>(null);

  const fetchCustomers = async () => {
    ApiProvider.getAllCustomers(`api/fetch-all-customer?search=`).then(
      (response) => {
        setCustomerList(response);
        let data = response.map((v, i) => {
          return {
            label: v.firstName + " " + v.lastName,
            value: JSON.stringify(v),
          };
        });
        setDropdownCustomerList(data);
      }
    );
  };

  const downloadPDF = async () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("payments.pdf");
    });
  };

  const fetchFinanceDetails = async () => {
    if (selectedCustomer === null) {
      return;
    }

    ApiProvider.getChitsOfCustomer(selectedCustomer).then((res) => {
      let arr1 = new Array<string>(res.length);
      arr1.fill("");
      setChitAmountsHoldingList(arr1);
      setChitSelectedDropdownList(arr1);

      let selectArr = new Array();
      res.forEach((c, i) => {
        let arr = new Array(c.number_of_months);
        arr.fill(0);
        let monthsList = arr.map((v, i) => {
          return {
            label: `Emi ${i + 1} (₹${
              c.emis[i].emiAmount *
              c.customers.filter((a) => a.id === selectedCustomer)[0].chitShare
            }) 
            ${
              c.customers.filter((a) => a.id === selectedCustomer)[0]
                .amountPaid[i].amount >=
              c.emis[i].emiAmount *
                c.customers.filter((a) => a.id === selectedCustomer)[0]
                  .chitShare
                ? "✅"
                : ""
            }
           
            (${
              "₹" +
              c.customers.filter((a) => a.id === selectedCustomer)[0]
                .amountPaid[i].amount
            } Paid)`,
            value: i,
          };
        });
        selectArr.push(monthsList);
      });

      setChitDropdownList(selectArr);
      setCustomerChits(res);
    });

    ApiProvider.getMicroFinancesOfCustomer(selectedCustomer).then((res) => {
      let arr1 = new Array<string>(res.length);
      arr1.fill("");
      setMicrofinanceAmountsHoldingList(arr1);
      setMicrofinanceSelectedDropdownList(arr1);

      let selectArr = new Array();
      res.forEach((m, i) => {
        let monthsList = m.receiving_amounts.map((v, i) => {
          return {
            label: `Emi ${i + 1} (₹${v.amount}) ${
              m.payments[i].amount >= v.amount ? "✅" : ""
            } (Paid ₹${m.payments[i].amount})`,
            value: i,
          };
        });
        selectArr.push(monthsList);
      });

      setMicrofinanceDropdownList(selectArr);
      setCustomerMicroFinances(res);
    });

    ApiProvider.getGeneralFinancesOfCustomer(selectedCustomer).then((res) => {
      let arr1 = new Array<string>(res.length);
      arr1.fill("");
      setGeneralfinanceAmountsHoldingList(arr1);
      setGeneralfinanceSelectedDropdownList(arr1);

      let selectArr = new Array();
      res.forEach((m, i) => {
        let monthsList = m.receiving_amounts.map((v, i) => {
          return {
            label: `Interest ${i + 1} (₹${v.amount}) ${
              m.payments[i].amount >= v.amount ? "✅" : ""
            } (Paid ₹${m.payments[i].amount})`,
            value: i,
          };
        });
        selectArr.push(monthsList);
      });

      setGeneralfinanceDropdownList(selectArr);
      setCustomerGeneralFinances(res);
    });
  };

  const getAllPayments = async () => {
    ApiProvider.getPayments(`api/get-payment?page=${page}&size=10`).then(
      (res) => {
        setTotalCount(Math.ceil(res.total / 10));
        setPayments(res.payments);
      }
    );
  };

  const clearFields = async () => {
    setSelectedCustomer(null);
    setCustomerChits([]);
    setCustomerMicroFinances([]);
    setCustomerGeneralFinances([]);
    setChitAmountsHoldingList([]);
    setMicrofinanceAmountsHoldingList([]);
    setGeneralfinanceAmountsHoldingList([]);
    setChitDropdownList([]);
    setMicrofinanceDropdownList([]);
    setGeneralfinanceDropdownList([]);
    setChitSelectedDropdownList([]);
    setMicrofinanceSelectedDropdownList([]);
    setGeneralfinanceSelectedDropdownList([]);
    setTotalAmount("");
    setRemarks("");
  };

  React.useEffect(() => {
    fetchCustomers();
  }, [refresh]);

  React.useEffect(() => {
    getAllPayments();
  }, [refresh, page]);

  React.useEffect(() => {
    fetchFinanceDetails();
  }, [refresh, selectedCustomer]);

  React.useEffect(() => {
    let chitAmount = chitAmountsHoldingList.reduce(
      (partialSum, a) => partialSum + parseInt(a === "" ? "0" : a),
      0
    );
    let microfinanceAmount = microfinanceAmountsHoldingList.reduce(
      (partialSum, a) => partialSum + parseInt(a === "" ? "0" : a),
      chitAmount
    );
    let generalfinanceAmount = generalfinanceAmountsHoldingList.reduce(
      (partialSum, a) => partialSum + parseInt(a === "" ? "0" : a),
      microfinanceAmount
    );
    setTotalAmount(generalfinanceAmount.toString());
  }, [
    chitAmountsHoldingList,
    microfinanceAmountsHoldingList,
    generalfinanceAmountsHoldingList,
  ]);

  // const customerListItem = (border: boolean, data: IPayment) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.paymentId}
  //       </Text>
  //       <Text
  //         className="text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.customer.customerId}
  //       </Text>
  //       <div className="flex flex-col items-start justify-start w-[15%] md:ml-[0] ml-[20px]">
  //         <Text
  //           className="text-base text-bluegray-900"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           {`${data.customer.firstName} ${data.customer.lastName}`}
  //         </Text>
  //         <Text
  //           className="text-base text-bluegray-400"
  //           size="txtInterRegular15Bluegray400"
  //         >
  //           {data.customer.phoneNumber}
  //         </Text>
  //       </div>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.totalAmount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {Constants.formatDateByTimeStamps(data.createdAt)}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[35%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.remarks}
  //       </Text>
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10px]"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         onClick={() => {
  //           setSelectedPayment(data);
  //           setViewPopup(true);
  //         }}
  //       >
  //         View
  //       </Button>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (border: boolean, data: IPayment) => (
    <tr
      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
      key={data.id}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {data.paymentId}
      </th>
      <td className="px-6 py-4">{data.customer.customerId}</td>
      <td className="px-6 py-4">
        {`${data.customer.firstName} ${data.customer.lastName}`}
        <br />
        {data.customer.phoneNumber}
      </td>
      <td className="px-6 py-4">{`₹${data.totalAmount}`}</td>
      <td className="px-6 py-4">
        {Constants.formatDateByTimeStamps(data.createdAt)}
      </td>
      <td className="px-6 py-4">{data.remarks}</td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10px]"
          color="indigo_500"
          size="xs"
          variant="outline"
          onClick={() => {
            setSelectedPayment(data);
            setViewPopup(true);
          }}
        >
          View
        </Button>
      </td>
      <td className="px-6 py-4">
        {PermissionClass.hasPermission(Permission.ADMIN) && (
          <img
            className="flex h-[30px] items-center justify-center rounded-[50%] w-[30px]"
            src="images/bin.png"
            alt="user One"
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to delete this payment?")
              ) {
                let cc: IChit[] = [];
                let cmf: IMicroFinance[] = [];
                let cgf: IGeneralFinance[] = [];
                await ApiProvider.getChitsOfCustomer(data.customer.id).then(
                  (v) => {
                    cc = v;
                  }
                );
                await ApiProvider.getMicroFinancesOfCustomer(
                  data.customer.id
                ).then((v) => {
                  cmf = v;
                });
                await ApiProvider.getGeneralFinancesOfCustomer(
                  data.customer.id
                ).then((v) => {
                  cgf = v;
                });
                data.chitFinances.map(async (v, i) => {
                  let paymentChit = cc.filter((c, i) => {
                    return c.id === v.financeId;
                  });
                  if (paymentChit.length > 0) {
                    let index = null;
                    let cs: ICustomerChit[] = paymentChit[0].customers.filter(
                      (cu, i) => {
                        if (cu.id === data.customer.id) {
                          index = i;
                          return true;
                        }
                        return false;
                      }
                    );
                    if (cs.length > 0) {
                      cs[0].amountPaid[v.month].amount -= v.amount;
                      cs[0].amountPaid[v.month].received_date = "";
                      cs[0].emiStatus[v.month] = EmiStatus.PENDING;
                    }
                    paymentChit[0].customers[index] = cs[0];
                    await ApiProvider.updateChit(
                      JSON.stringify({
                        customers: JSON.stringify(paymentChit[0].customers),
                      }),
                      paymentChit[0].id
                    ).then((res) => {
                      console.log(res);
                    });
                  }
                });
                data.microFinances.map(async (v, i) => {
                  let paymentMf = cmf.filter((c, i) => {
                    return c.id === v.financeId;
                  });
                  if (paymentMf.length > 0) {
                    let mfp: IMFPayment[] = paymentMf[0].payments;
                    mfp[v.month].amount -= v.amount;
                    mfp[v.month].received_date = "";

                    await ApiProvider.updateMicroFinance(
                      JSON.stringify({ payments: JSON.stringify(mfp) }),
                      v.financeId
                    ).then((res) => {
                      console.log(res);
                    });
                  }
                });
                data.generalFinances.map(async (v, i) => {
                  let paymentGf = cgf.filter((c, i) => {
                    return c.id === v.financeId;
                  });
                  if (paymentGf.length > 0) {
                    let gfp: IMFPayment[] = paymentGf[0].payments;
                    gfp[v.month].amount -= v.amount;
                    gfp[v.month].received_date = "";

                    await ApiProvider.updateGeneralFinance(
                      JSON.stringify({ payments: JSON.stringify(gfp) }),
                      v.financeId
                    ).then((res) => {
                      console.log(res);
                    });
                  }
                });
                await ApiProvider.delete(`api/delete-payment/${data.id}`).then(
                  (v) => {
                    if (v == true) {
                      toast("Payemnt Deleted");
                      window.location.reload();
                    } else {
                      toast("Payment not deleted! Try again later..");
                    }
                  }
                );
              }
            }}
          />
        )}
      </td>
    </tr>
  );

  const addPayment = async () => {
    if (selectedCustomer == null) {
      toast("Please select a customer");
      return;
    }

    if (parseInt(totalAmount) == 0) {
      toast("Total amount is summing to 0. Please add some data!");
      return;
    }

    if (
      chitAmountsHoldingList.length == 0 &&
      microfinanceAmountsHoldingList.length == 0 &&
      generalfinanceAmountsHoldingList.length == 0
    ) {
      toast("No data is available for the customer");
      return;
    }

    let list1 = chitAmountsHoldingList.filter((v, i) => {
      return (
        (v === "" && chitSelectedDropdownList[i] !== "") ||
        (v !== "" && chitSelectedDropdownList[i] === "")
      );
    });

    let list2 = microfinanceAmountsHoldingList.filter((v, i) => {
      return (
        (v === "" && microfinanceSelectedDropdownList[i] !== "") ||
        (v !== "" && microfinanceSelectedDropdownList[i] === "")
      );
    });

    let list3 = generalfinanceAmountsHoldingList.filter((v, i) => {
      return (
        (v === "" && generalfinanceSelectedDropdownList[i] !== "") ||
        (v !== "" && generalfinanceSelectedDropdownList[i] === "")
      );
    });

    if (list1.length > 0 || list2.length > 0 || list3.length > 0) {
      toast(
        "Please select correct EMI and enter amount. Either the Dropdown is not selected and amount is entered or amount is not entered but Dropdown is selected"
      );
      return;
    }

    let chitFinances: IFinance[] = [];
    let microFinances: IFinance[] = [];
    let generalFinances: IFinance[] = [];

    let today = new Date().toISOString().slice(0, 10);

    customerChits.forEach(async (v, i) => {
      if (
        chitAmountsHoldingList[i] === "" &&
        chitSelectedDropdownList[i] === ""
      ) {
      } else {
        let finance: IFinance = {
          financeId: v.id,
          customId: v.chit_id,
          month: parseInt(chitSelectedDropdownList[i]),
          amount: parseInt(chitAmountsHoldingList[i]),
          type: FinanceType.CHITFINANCE,
          financeName: v.chit_name,
          financeStartDate: v.start_date,
        };
        chitFinances.push(finance);

        let customers: ICustomerChit[] = v.customers;
        let index: number = null;

        let filteredCustomers = customers.filter((v, i) => {
          if (v.id === selectedCustomer) {
            index = i;
            return true;
          }
          return false;
        });

        let modifiedData: ICustomerChit = filteredCustomers[0];
        modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])].amount +=
          parseInt(chitAmountsHoldingList[i]);
        modifiedData.amountPaid[
          parseInt(chitSelectedDropdownList[i])
        ].received_date = today;
        if (
          modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])]
            .amount == 0
        ) {
          modifiedData.emiStatus[parseInt(chitSelectedDropdownList[i])] =
            EmiStatus.PENDING;
        } else if (
          modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])]
            .amount > 0 &&
          modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])]
            .amount <
            v.emis[parseInt(chitSelectedDropdownList[i])].emiAmount *
              modifiedData.chitShare
        ) {
          modifiedData.emiStatus[parseInt(chitSelectedDropdownList[i])] =
            EmiStatus.PARTIALLY_PAID;
        } else if (
          modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])]
            .amount ==
          v.emis[parseInt(chitSelectedDropdownList[i])].emiAmount *
            modifiedData.chitShare
        ) {
          modifiedData.emiStatus[parseInt(chitSelectedDropdownList[i])] =
            EmiStatus.PAID;
        } else if (
          modifiedData.amountPaid[parseInt(chitSelectedDropdownList[i])]
            .amount >
          v.emis[parseInt(chitSelectedDropdownList[i])].emiAmount *
            modifiedData.chitShare
        ) {
          modifiedData.emiStatus[parseInt(chitSelectedDropdownList[i])] =
            EmiStatus.OVER_PAID;
        }
        customers[index] = modifiedData;

        await ApiProvider.updateChit(
          JSON.stringify({ customers: JSON.stringify(customers) }),
          v.id
        ).then((res) => {
          console.log(res);
        });
      }
    });

    customerMicroFinances.forEach(async (v, i) => {
      if (
        microfinanceSelectedDropdownList[i] === "" &&
        microfinanceAmountsHoldingList[i] === ""
      ) {
      } else {
        let finance: IFinance = {
          financeId: v.id,
          customId: v.mf_id,
          month: parseInt(microfinanceSelectedDropdownList[i]),
          amount: parseInt(microfinanceAmountsHoldingList[i]),
          type: FinanceType.MICROFINANCE,
          financeName: "",
          financeStartDate: v.start_date,
        };
        microFinances.push(finance);

        let mfPayments: IMFPayment[] = v.payments;

        mfPayments[parseInt(microfinanceSelectedDropdownList[i])].amount +=
          parseInt(microfinanceAmountsHoldingList[i]);
        mfPayments[
          parseInt(microfinanceSelectedDropdownList[i])
        ].received_date = today;

        await ApiProvider.updateMicroFinance(
          JSON.stringify({ payments: JSON.stringify(mfPayments) }),
          v.id
        ).then((res) => {
          console.log(res);
        });
      }
    });

    customerGeneralFinances.forEach(async (v, i) => {
      if (
        generalfinanceSelectedDropdownList[i] === "" &&
        generalfinanceAmountsHoldingList[i] === ""
      ) {
      } else {
        let finance: IFinance = {
          financeId: v.id,
          customId: v.gf_id,
          month: parseInt(generalfinanceSelectedDropdownList[i]),
          amount: parseInt(generalfinanceAmountsHoldingList[i]),
          type: FinanceType.GENERALFINANCE,
          financeName: "",
          financeStartDate: v.start_date,
        };
        generalFinances.push(finance);

        let gfPayments: IMFPayment[] = v.payments;

        gfPayments[parseInt(generalfinanceSelectedDropdownList[i])].amount +=
          parseInt(generalfinanceAmountsHoldingList[i]);
        gfPayments[
          parseInt(generalfinanceSelectedDropdownList[i])
        ].received_date = today;

        await ApiProvider.updateGeneralFinance(
          JSON.stringify({ payments: JSON.stringify(gfPayments) }),
          v.id
        ).then((res) => {
          console.log(res);
        });
      }
    });

    await ApiProvider.addPayment(
      JSON.stringify({
        customer: selectedCustomer,
        chitFinances: chitFinances,
        microFinances: microFinances,
        generalFinances: generalFinances,
        totalAmount: parseInt(totalAmount),
        remarks: remarks,
      })
    ).then((res) => {
      if (res == true) {
        setAddPopup(false);
        clearFields();
        setRefresh(!refresh);
        toast("Payment Added");
      } else {
        toast("Payment addition failed. Please try again later!");
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex sm:flex-row md:flex-row lg:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Payments
            </Text>
          </div>
          <div className="flex flex-row">
            <Button
              className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
              shape="circle"
              color="gray_102"
              size="xs"
              variant="fill"
              onClick={downloadPDF}
            >
              <img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="h-[30px]"
                src="images/pdf_export.png"
                alt="PDF Export"
              />
            </Button>
            {PermissionClass.hasPermission(Permission.PAYMENTWRITE) && (
              <Button
                className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
                shape="circle"
                color="gray_102"
                size="xs"
                variant="fill"
                onClick={() => setAddPopup(true)}
              >
                <Img
                  style={{
                    filter: Constants.imgFilter,
                  }}
                  className="h-[30px]"
                  src="images/img_add.png"
                  alt="user One"
                />
              </Button>
            )}
          </div>
        </div>
        <div
          className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full"
          ref={pdfRef}
        >
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <Text
              className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
              size="txtInterSemiBold22"
            >
              Payments Overview
            </Text>
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {" "}
                        Payment Id
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Customer Id
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Customer
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Amount
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Remarks
                      </th>
                      <th scope="col" className="px-6 py-3">
                        View
                      </th>
                      {PermissionClass.hasPermission(Permission.ADMIN) && (
                        <th scope="col" className="px-6 py-3">
                          Delete
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((e, i) =>
                      customerListItem(i != payments.length - 1, e)
                    )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Payment Id
                    </Text>
                    <Text
                      className="text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Customer Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Customer
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Amount
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Date
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[35%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Remarks
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      View
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {payments.map((e, i) =>
                  customerListItem(i != payments.length - 1, e)
                )}
              </List> */}
            </div>
          </div>
        </div>
        {totalCount != null && totalCount > 1 && (
          <div className="flex flex-row items-center w-[30%] md:w-full">
            <div
              className="flex flex-row items-center justify-evenly w-[28%] cursor-pointer"
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Previous
              </Text>
            </div>
            <Button
              className="cursor-pointer font-medium h-10 leading-[normal] ml-3 rounded-[10px] text-[15px] text-center w-10"
              color="indigo_600"
              size="sm"
              variant="fill"
            >
              {page}
            </Button>
            <div
              className="flex flex-row items-center justify-evenly ml-3 w-[25%] cursor-pointer"
              onClick={() => {
                if (page < totalCount) {
                  setPage(page + 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Next
              </Text>
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowright.svg"
                alt="arrowright"
              />
            </div>
          </div>
        )}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto mobile__w_100_emi__payments">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[50vw] mobile__w_100_emi__payments">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Payment
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        clearFields();
                        setAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Customer
                  </Text>
                  <SelectBox
                    className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                    placeholderClassName="text-bluegray-900"
                    indicator={
                      <Img
                        style={{
                          filter: Constants.imgFilter,
                        }}
                        className="h-6 mr-[19px] w-6"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isMulti={false}
                    options={dropdownCustomerList}
                    isSearchable={true}
                    shape="round"
                    color="white_A700"
                    size="xs"
                    variant="fill"
                    onChange={(value) => {
                      let customer: ICustomer = JSON.parse(value);
                      setSelectedCustomer(customer.id);
                    }}
                  />
                </div>
                {customerChits.length > 0 && (
                  <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterSemiBold18"
                    >
                      CHIT FINANCES
                    </Text>
                    {customerChits.map((c, i) => {
                      return (
                        <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                          <div className="w-[50%]">
                            <Text
                              className="text-base text-bluegray-400"
                              size="txtInterRegular16Bluegray400"
                            >
                              {c.chit_id} <br /> {c.chit_name}
                            </Text>
                          </div>
                          <SelectBox
                            className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                            placeholderClassName="text-bluegray-900"
                            indicator={
                              <Img
                                style={{
                                  filter: Constants.imgFilter,
                                }}
                                className="h-6 mr-[19px] w-6"
                                src="images/img_arrowdown.svg"
                                alt="arrow_down"
                              />
                            }
                            isMulti={false}
                            options={chitDropdownList[i]}
                            isSearchable={true}
                            shape="round"
                            color="white_A700"
                            size="xs"
                            variant="fill"
                            onChange={(value) => {
                              const updatedSelected = [
                                ...chitSelectedDropdownList,
                              ];
                              updatedSelected[i] = value;
                              setChitSelectedDropdownList(updatedSelected);
                            }}
                          />
                          <Input
                            name="Group195 Two"
                            className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                            shape="round"
                            color="white_A700"
                            placeholder="Amount"
                            value={chitAmountsHoldingList[i]}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e === "" || re.test(e)) {
                                const amountList = [...chitAmountsHoldingList];
                                amountList[i] = e;
                                setChitAmountsHoldingList(amountList);
                              }
                            }}
                          ></Input>
                        </div>
                      );
                    })}
                  </div>
                )}
                {customerMicroFinances.length > 0 && (
                  <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterSemiBold18"
                    >
                      MICRO FINANCES
                    </Text>
                    {customerMicroFinances.map((m, i) => {
                      return (
                        <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                          <div className="w-[50%]">
                            <Text
                              className="text-base text-bluegray-400"
                              size="txtInterRegular16Bluegray400"
                            >
                              {m.mf_id} <br />{" "}
                              {Constants.formatDate(m.start_date)}
                            </Text>
                          </div>
                          <SelectBox
                            className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                            placeholderClassName="text-bluegray-900"
                            indicator={
                              <Img
                                style={{
                                  filter: Constants.imgFilter,
                                }}
                                className="h-6 mr-[19px] w-6"
                                src="images/img_arrowdown.svg"
                                alt="arrow_down"
                              />
                            }
                            isMulti={false}
                            options={microfinanceDropdownList[i]}
                            isSearchable={true}
                            shape="round"
                            color="white_A700"
                            size="xs"
                            variant="fill"
                            onChange={(value) => {
                              const updatedSelected = [
                                ...microfinanceSelectedDropdownList,
                              ];
                              updatedSelected[i] = value;
                              setMicrofinanceSelectedDropdownList(
                                updatedSelected
                              );
                            }}
                          />
                          <Input
                            name="Group195 Two"
                            className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                            shape="round"
                            color="white_A700"
                            placeholder="Amount"
                            value={microfinanceAmountsHoldingList[i]}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e === "" || re.test(e)) {
                                const amountList = [
                                  ...microfinanceAmountsHoldingList,
                                ];
                                amountList[i] = e;
                                setMicrofinanceAmountsHoldingList(amountList);
                              }
                            }}
                          ></Input>
                        </div>
                      );
                    })}
                  </div>
                )}
                {customerGeneralFinances.length > 0 && (
                  <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterSemiBold18"
                    >
                      GENERAL FINANCES
                    </Text>
                    {customerGeneralFinances.map((g, i) => {
                      return (
                        <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                          <div className="w-[50%]">
                            <Text
                              className="text-base text-bluegray-400"
                              size="txtInterRegular16Bluegray400"
                            >
                              {g.gf_id} <br />{" "}
                              {Constants.formatDate(g.start_date)}
                            </Text>
                          </div>
                          <SelectBox
                            className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                            placeholderClassName="text-bluegray-900"
                            indicator={
                              <Img
                                style={{
                                  filter: Constants.imgFilter,
                                }}
                                className="h-6 mr-[19px] w-6"
                                src="images/img_arrowdown.svg"
                                alt="arrow_down"
                              />
                            }
                            isMulti={false}
                            options={generalfinanceDropdownList[i]}
                            isSearchable={true}
                            shape="round"
                            color="white_A700"
                            size="xs"
                            variant="fill"
                            onChange={(value) => {
                              const updatedSelected = [
                                ...generalfinanceSelectedDropdownList,
                              ];
                              updatedSelected[i] = value;
                              setGeneralfinanceSelectedDropdownList(
                                updatedSelected
                              );
                            }}
                          />
                          <Input
                            name="Group195 Two"
                            className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                            shape="round"
                            color="white_A700"
                            placeholder="Amount"
                            value={generalfinanceAmountsHoldingList[i]}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e === "" || re.test(e)) {
                                const amountList = [
                                  ...generalfinanceAmountsHoldingList,
                                ];
                                amountList[i] = e;
                                setGeneralfinanceAmountsHoldingList(amountList);
                              }
                            }}
                          ></Input>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Remarks
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={remarks}
                      onChange={(v) => {
                        setRemarks(v);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Total Amount
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      disabled
                      value={totalAmount}
                      color="white_A700"
                    ></Input>
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Amount in words ={" "}
                  {`${Constants.capitalizeFirstLetter(
                    numWords(parseInt(totalAmount))
                  )}`}
                </Text>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addPayment}
                >
                  Add Payment
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {selectedPayment != null && (
        <section
          style={{ background: "rgba(0,0,0,0.4)" }}
          className={
            viewPopup
              ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
              : "hidden"
          }
        >
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
            <form onSubmit={(e) => {}}>
              <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[50vw]">
                <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                  <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <Text
                      className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                      size="txtInterSemiBold22"
                    >
                      Finance Id's
                    </Text>
                    <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                      <IoMdClose
                        onClick={() => {
                          setViewPopup(false);
                          setSelectedPayment(null);
                        }}
                      />
                    </div>
                  </div>
                  {selectedPayment.chitFinances.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        CHIT FINANCES
                      </Text>
                      {selectedPayment.chitFinances.map((c, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {c.customId +
                                  " " +
                                  `(EMI${c.month + 1} ₹${
                                    c.amount
                                  }) Start Date : ${Constants.formatDate(
                                    c.financeStartDate ?? ""
                                  )} Name : ${c.financeName ?? ""}`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedPayment.microFinances.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        MICRO FINANCES
                      </Text>
                      {selectedPayment.microFinances.map((m, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {m.customId +
                                  " " +
                                  `(EMI${m.month + 1} ₹${
                                    m.amount
                                  }) Start Date : ${Constants.formatDate(
                                    m.financeStartDate ?? ""
                                  )}`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedPayment.generalFinances.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        GENERAL FINANCES
                      </Text>
                      {selectedPayment.generalFinances.map((g, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {g.customId +
                                  " " +
                                  `(EMI${g.month + 1} ₹${
                                    g.amount
                                  }) Start Date : ${Constants.formatDate(
                                    g.financeStartDate ?? ""
                                  )}`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Payments;
