import { IMFPayment } from "./micro_finance_interfaces";

export interface IChit {
    id?: string;
    chit_id? : string;
    chit_name : string;
    chit_amount :  number;
    start_date : string;
    end_date? : string;
    number_of_months : number;
    commission : number;
    customers : ICustomerChit[];
    auctions : IAuction[];
    emis : IEmi[];
}

export interface IAuction {
  auction_id: string;  
  auctionAmount?: number;
  distributionAmount?: number;
  distributionDate?: string;
  auctionDate?: string;
  auctionWinner?: string;
  index: number;
}

export interface ICustomerChit {
    id: string;
    customerId: string;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    chitShare: number;
    amountPaid: IMFPayment[];
    emiStatus: EmiStatus[];
}

export interface IEmi {
    emiAmount: number;
    index : number;
    emiDate? : string;
}

export enum EmiStatus {
    PENDING = "Pending",
    PAID = "Paid",
    PARTIALLY_PAID = "Partially Paid",
    OVER_PAID = "Over Paid"
}
