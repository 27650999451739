import { ProSidebarProvider } from "react-pro-sidebar";
import React, { useEffect } from "react";
import Routes from "./Routes";
import GlobalSidebar from "components/Sidebar1";
import { useLocation } from "react-router-dom";
import ProjectRoutes from "./Routes";

function App() {
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsSideBarOpen(false);
    }
  }, [location.pathname]);

  return (
    <ProSidebarProvider>
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start mx-auto pb-[30px] w-full">
        {isSideBarOpen && (
          <GlobalSidebar
            setIsSideBarOpen={(v) => {
              setIsSideBarOpen(v);
            }}
          />
        )}
        <ProjectRoutes
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={(v) => {
            setIsSideBarOpen(v);
          }}
        />
      </div>
      {location.pathname !== "/" &&  <p className="flex flex-row items-center justify-center pt-2 pb-2">Copyright @2023 | Swavik Technologies ®</p>}
    </ProSidebarProvider>
  );
}

export default App;


