import React from "react";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  SelectBox,
  Switch,
  Text,
} from "components";
import {
  IBorrowing,
  ILendor,
  OtherStatus,
} from "interfaces/remaining_interfaces";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdClose } from "react-icons/io";
import { Constants } from "constants/constants";
import { IGlobalProps } from "Routes";
import numWords from "num-words";

const Borrowings = (props: IGlobalProps) => {
  // const customerListItem = (border: boolean, data: IBorrowing) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.borrowing_id}
  //       </Text>
  //       <div className="flex flex-col items-start justify-start w-[15%] md:ml-[0] ml-[20px]">
  //         <Text
  //           className="text-base text-bluegray-900"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           {`${data.lendor.firstName} ${data.lendor.lastName}`}
  //         </Text>
  //         <Text
  //           className="text-base text-bluegray-400"
  //           size="txtInterRegular15Bluegray400"
  //         >
  //           {data.lendor.phoneNumber}
  //         </Text>
  //       </div>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.loanAmount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.receivedAmount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.paidAmount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`${data.interest}%`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {Constants.formatDate(data.receivedDate)}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.type}
  //       </Text>
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10px]"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         type="button"
  //         onClick={() => {
  //           setEditLoanRecd(data.receivedAmount.toString());
  //           setEditLoanPaid(data.paidAmount.toString());
  //           setSelectedBorrowingEditId(data.id);
  //           setBorrowingEditPopup(true);
  //         }}
  //       >
  //         Edit
  //       </Button>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (border: boolean, data: IBorrowing) => (
    <tr
      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
      key={data.id}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {data.borrowing_id}
      </th>
      <td className="px-6 py-4">
        {`${data.lendor.firstName} ${data.lendor.lastName}`}
        <br />
        {data.lendor.phoneNumber}
      </td>
      <td className="px-6 py-4">{`₹${data.loanAmount}`}</td>
      <td className="px-6 py-4">{`₹${data.receivedAmount}`}</td>
      <td className="px-6 py-4">{`₹${data.paidAmount}`}</td>
      <td className="px-6 py-4">{`₹${
        data.receivedAmount - data.paidAmount
      }`}</td>
      <td className="px-6 py-4">{`${data.interest}%`}</td>
      <td className="px-6 py-4">{Constants.formatDate(data.receivedDate)}</td>
      <td className="px-6 py-4">{data.type}</td>
      <td className="px-6 py-4">
        {data.status == OtherStatus.PENDING ? (
          <Button
            className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10px]"
            color="indigo_500"
            size="xs"
            variant="outline"
            type="button"
            onClick={() => {
              setEditLoanRecd(data.receivedAmount.toString());
              setEditLoanPaid(data.paidAmount.toString());
              setSelectedBorrowingEditId(data.id);
              setBorrowingEditPopup(true);
            }}
          >
            Edit
          </Button>
        ) : (
          <Text
            className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
            size="txtInterRegular16Bluegray900"
          >
            Completed
          </Text>
        )}
      </td>
      <td className="px-6 py-4">
        {data.status == OtherStatus.PENDING ? (
          <Button
            className="cursor-pointer font-medium sm:w-100 md:w-100 lg:w-[15%] leading-[normal] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to complete this Borrowing"
                )
              ) {
                ApiProvider.put(
                  `api/update-borrowing/${data.id}`,
                  JSON.stringify({
                    status: OtherStatus.COMPLETED,
                  })
                ).then((v) => {
                  if (v == true) {
                    toast("Borrowing marked as completed");
                    window.location.reload();
                  } else {
                    toast(
                      "Borrowing not marked as completed! Please try later.."
                    );
                  }
                });
              }
            }}
          >
            Mark Complete
          </Button>
        ) : (
          <Text
            className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
            size="txtInterRegular16Bluegray900"
          >
            Completed
          </Text>
        )}
      </td>
    </tr>
  );

  // const lendorListItem = (border: boolean, data: ILendor) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.lendorId}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.firstName}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.lastName}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.phoneNumber}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[40%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.address}
  //       </Text>
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         type="button"
  //         onClick={() => {
  //           setEditFirstName(data.firstName);
  //           setEditLastName(data.lastName);
  //           setEditMobileNumber(data.phoneNumber.toString());
  //           setEditAddress(data.address);
  //           setSelectedEditId(data.id);
  //           setLendorEditPopup(true);
  //         }}
  //       >
  //         Edit
  //       </Button>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const lendorListItem = (border: boolean, data: ILendor) => (
    <tr
      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
      key={data.id}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {data.lendorId}
      </th>
      <td className="px-6 py-4">{data.firstName}</td>
      <td className="px-6 py-4">{data.lastName}</td>
      <td className="px-6 py-4">{data.phoneNumber}</td>
      <td className="px-6 py-4">{data.address}</td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
          color="indigo_500"
          size="xs"
          variant="outline"
          type="button"
          onClick={() => {
            setEditFirstName(data.firstName);
            setEditLastName(data.lastName);
            setEditMobileNumber(data.phoneNumber.toString());
            setEditAddress(data.address);
            setSelectedEditId(data.id);
            setLendorEditPopup(true);
          }}
        >
          Edit
        </Button>
      </td>
    </tr>
  );

  const [refresh, setRefresh] = React.useState(true);

  const [lendors, setLendors] = React.useState<ILendor[]>([]);
  const [borrowings, setBorrowings] = React.useState<IBorrowing[]>([]);

  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");

  const [selectedEditId, setSelectedEditId] = React.useState<string>("");
  const [editFirstName, setEditFirstName] = React.useState<string>("");
  const [editLastName, setEditLastName] = React.useState<string>("");
  const [editAddress, setEditAddress] = React.useState<string>("");
  const [editMobileNumber, setEditMobileNumber] = React.useState<string>("");

  const [lendorAddPopup, setLendorAddPopup] = React.useState(false);
  const [lendorEditPopup, setLendorEditPopup] = React.useState(false);

  const [borrowingAddPopup, setBorrowingAddPopup] = React.useState(false);
  const [borrowingEditPopup, setBorrowingEditPopup] = React.useState(false);

  const [lendor, setLendor] = React.useState<string>("");
  const [loanAmount, setLoanAmount] = React.useState<string>("");
  const [loanRecd, setLoanRecd] = React.useState<string>("");
  const [interest, setInterest] = React.useState<string>("");
  const [loanPaid, setLoanPaid] = React.useState<string>("");
  const [recdDate, setRecdDate] = React.useState<string>("");
  const [type, setType] = React.useState<string>("");

  const [selectedBorrowingEditId, setSelectedBorrowingEditId] =
    React.useState<string>("");
  const [editLoanPaid, setEditLoanPaid] = React.useState<string>("");
  const [editLoanRecd, setEditLoanRecd] = React.useState<string>("");

  const [active, setActive] = React.useState<boolean>(true);

  const addBorrowing = async () => {
    if (
      lendor == "" ||
      loanAmount == "" ||
      loanRecd == "" ||
      recdDate == "" ||
      type == "" ||
      interest == ""
    ) {
      toast("Fill all fields!");
      return;
    }
    let data = {
      lendor: lendor,
      loan_amount: parseInt(loanAmount),
      received_amount: parseInt(loanRecd),
      paid_amount: parseInt("0"),
      interest: interest,
      received_date: recdDate,
      type: type,
      status: OtherStatus.PENDING,
    };

    ApiProvider.post(JSON.stringify(data), "api/add-borrowing").then((res) => {
      if (res == true) {
        setBorrowingAddPopup(false);
        clearAllFields();
        toast("Borrowing added successfully");
        setRefresh(!refresh);
      } else {
        toast("Borrowing not added");
      }
    });
  };

  const updateBorrowing = async () => {
    if (editLoanRecd == "" || editLoanPaid == "") {
      toast("Fill all fields!");
      return;
    }
    let data = {
      received_amount: parseInt(editLoanRecd),
      paid_amount: parseInt(editLoanPaid),
    };

    ApiProvider.put(
      `api/update-borrowing/${selectedBorrowingEditId}`,
      JSON.stringify(data)
    ).then((res) => {
      if (res == true) {
        clearAllFields();
        toast("Borrowing updated successfully");
        setRefresh(!refresh);
      } else {
        toast("Borrowing not updated");
      }
      setBorrowingEditPopup(false);
    });
  };

  const addLendor = async () => {
    if (
      firstName == "" ||
      lastName == "" ||
      mobileNumber == "" ||
      address == ""
    ) {
      toast("Fill all fields!");
      return;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: parseInt(mobileNumber),
      address: address,
    };

    ApiProvider.post(JSON.stringify(data), "api/add-lendor").then((res) => {
      if (res == true) {
        setLendorAddPopup(false);
        clearAllFields();
        toast("Lendor added successfully");
        setRefresh(!refresh);
      } else {
        toast("Lendor not added");
      }
    });
  };

  const updateLendor = async () => {
    if (
      editFirstName == "" ||
      editLastName == "" ||
      editMobileNumber == "" ||
      editAddress == ""
    ) {
      toast("Fill all fields!");
      return;
    }
    let data = {
      first_name: editFirstName,
      last_name: editLastName,
      phone_number: parseInt(editMobileNumber),
      address: editAddress,
    };

    ApiProvider.put(
      `api/update-lendor/${selectedEditId}`,
      JSON.stringify(data)
    ).then((res) => {
      if (res == true) {
        clearAllFields();
        toast("Lendor updated successfully");
        setRefresh(!refresh);
      } else {
        toast("Lendor not updated");
      }
      setLendorEditPopup(false);
    });
  };

  const clearAllFields = () => {
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setAddress("");
    setLendor("");
    setLoanAmount("");
    setLoanRecd("");
    setLoanPaid("");
    setRecdDate("");
    setType("");
    setInterest("");
  };

  const fetchData = async () => {
    await ApiProvider.getAllLendors().then((res) => {
      setLendors(res);
    });
    await ApiProvider.getAllBorrowings(
      active ? OtherStatus.PENDING : OtherStatus.COMPLETED
    ).then((res) => {
      setBorrowings(res);
    });
  };

  React.useEffect(() => {
    fetchData();
  }, [refresh, active]);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Borrowings
            </Text>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <div className="flex flex-row">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Lendors Overview
              </Text>
              <Button
                className="flex h-[30px] items-center justify-center rounded-[50%] w-[30px] ml-[20px]"
                shape="circle"
                color="white_A700"
                size="xs"
                variant="fill"
                type="button"
                onClick={() => {
                  setLendorAddPopup(true);
                }}
              >
                <Img
                  style={{
                    filter: Constants.imgFilter,
                  }}
                  className="h-[15px]"
                  src="images/img_add.png"
                  alt="user One"
                />
              </Button>
            </div>
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Id
                      </th>
                      <th scope="col" className="px-6 py-3">
                        First Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Last Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Mobile
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Address
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lendors.map((e, i) =>
                      lendorListItem(i != lendors.length - 1, e)
                    )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Mobile
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[40%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Address
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Edit
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {lendors.map((e, i) =>
                  lendorListItem(i != lendors.length - 1, e)
                )}
              </List> */}
            </div>
          </div>
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <div className="flex flex-row items-center">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Borrowing Details
              </Text>
              <Switch
                onColor="#359766"
                offColor="#e3e4e8"
                onHandleColor="#ffffff"
                offHandleColor="#ffffff"
                value={active}
                className="w-[15%] ml-[20px]"
                onChange={(v) => {
                  setActive(v);
                }}
              />
              <Button
                className="flex h-[30px] items-center justify-center rounded-[50%] w-[30px] ml-[20px]"
                shape="circle"
                color="white_A700"
                size="xs"
                variant="fill"
                type="button"
                onClick={() => {
                  setBorrowingAddPopup(true);
                }}
              >
                <Img
                  style={{
                    filter: Constants.imgFilter,
                  }}
                  className="h-[15px]"
                  src="images/img_add.png"
                  alt="user One"
                />
              </Button>
            </div>
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Id
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Lendor
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Loan Amount
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Loan Received
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Loan Paid
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Balance
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Interest
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Received Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Edit
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {borrowings.map((e, i) =>
                      customerListItem(i != borrowings.length - 1, e)
                    )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Lendor
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Loan Amount
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Loan Received
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Loan Paid
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Interest
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Received Date
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Type
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Edit
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {borrowings.map((e, i) =>
                  customerListItem(i != borrowings.length - 1, e)
                )}
              </List> */}
            </div>
          </div>
        </div>
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          lendorAddPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Lendor
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setLendorAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={mobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setMobileNumber(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Address
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={address}
                      onChange={(e) => {
                        setAddress(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addLendor}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          lendorEditPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Edit Lendor
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setLendorEditPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editFirstName}
                      onChange={(e) => {
                        setEditFirstName(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editLastName}
                      onChange={(e) => {
                        setEditLastName(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editMobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setEditMobileNumber(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Address
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editAddress}
                      onChange={(e) => {
                        setEditAddress(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={updateLendor}
                >
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          borrowingAddPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Borrowing
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setBorrowingAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Loan Amount
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={loanAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setLoanAmount(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Loan Received
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={loanRecd}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setLoanRecd(e);
                        }
                      }}
                    ></Input>
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Loan amount in words ={" "}
                  {`${Constants.capitalizeFirstLetter(
                    numWords(parseInt(loanAmount))
                  )}`}
                </Text>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Loan Recd in words ={" "}
                  {`${Constants.capitalizeFirstLetter(
                    numWords(parseInt(loanRecd))
                  )}`}
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Interest
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={interest}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setInterest(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Received Date
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={recdDate}
                      type="date"
                      onChange={(e) => {
                        setRecdDate(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Lendor
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          style={{
                            filter: Constants.imgFilter,
                          }}
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                        />
                      }
                      isMulti={false}
                      options={lendors.map((v, i) => {
                        return {
                          label: v.firstName + " " + v.lastName,
                          value: v.id,
                        };
                      })}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setLendor(value);
                      }}
                    />
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Loan Type
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={type}
                      onChange={(e) => {
                        setType(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addBorrowing}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          borrowingEditPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Edit Borrowing
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setBorrowingEditPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Received Amount
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editLoanRecd}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setEditLoanRecd(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Loan Paid
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editLoanPaid}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setEditLoanPaid(e);
                        }
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={updateBorrowing}
                >
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Borrowings;
