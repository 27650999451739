import React from "react";
import { Button, CheckBox, Img, Input, Text } from "components";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Signin: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const navigate = useNavigate();

  const login = async () => {
    if (email == "" || password == "") {
      toast("Provide email and password");
    }

    const url = ApiProvider.baseUrl + "api/login";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = {
      email: email,
      password: password,
    };

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                toast(res.message);
              } else {
                localStorage.setItem("vsm", res.data.accessToken);
                localStorage.setItem("vsmemail", res.data.email);
                localStorage.setItem("vsmaccess", res.data.permission);
                document.location.href = "/dashboard";
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-gray-100 flex flex-col font-inter items-start justify-start mx-auto md:px-10 sm:px-5 px-[130px] py-[64px] w-auto sm:w-full md:w-full h-[100vh]">
        <div className="flex md:flex-col flex-row gap-[50px] items-center justify-start max-w-[1260px] mx-auto w-full">
          <div className="flex flex-1 flex-col md:gap-10 gap-[84px] items-center justify-start w-full">
            <Text
              className="sm:text-2xl md:text-[26px] text-[28px] text-bluegray-900 text-center w-full"
              size="txtInterBold25"
            >
              Manage your finances more effectively
            </Text>
            <Img
              className="h-[483px] w-full"
              src="images/img_workingtime.svg"
              alt="workingtime"
            />
          </div>
          <div className="bg-white-A700 flex flex-1 flex-col items-start justify-start md:px-10 sm:px-5 p-[50px] rounded-[20px] w-full">
            <div className="flex flex-col gap-12 items-center justify-start w-full">
              <div className="flex flex-col gap-[49px] items-center justify-start w-full">
                <Text
                  className="text-2xl md:text-[22px] text-bluegray-800 text-center sm:text-xl w-full"
                  size="txtInterBold16Indigo600"
                >
                  Login to VSM
                </Text>
                <div className="flex flex-col gap-4 items-start justify-start w-full">
                  <div className="flex flex-col gap-7 items-start justify-start w-full">
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <Text
                        className="text-bluegray-800 text-sm w-full"
                        size="txtInterBold16Indigo600"
                      >
                        Email
                      </Text>
                      <Input
                        name="email_One"
                        placeholder="xyz@gmail.com"
                        className="p-0 placeholder:text-bluegray-400 sm:pr-5 text-base text-bluegray-400 text-left w-full"
                        wrapClassName="bg-white-A700 border border-indigo-50 border-solid flex pl-5 pr-[35px] py-[17px] rounded w-full"
                        type="email"
                        prefix={
                          <Img
                            className="mt-auto mb-px h-5 mr-4"
                            src="images/img_mail_bluegray_800.svg"
                            alt="mail"
                          />
                        }
                        value={email}
                        onChange={(v) => {
                          setEmail(v);
                        }}
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <Text
                        className="text-bluegray-800 text-sm w-full"
                        size="txtInterBold16Indigo600"
                      >
                        Password
                      </Text>
                      <Input
                        name="password_One"
                        placeholder="************"
                        className="p-0 placeholder:text-bluegray-400 sm:pr-5 text-base text-bluegray-400 text-left w-full"
                        wrapClassName="bg-white-A700 border border-indigo-50 border-solid flex pb-[18px] pl-5 pr-[35px] pt-[15px] rounded w-full"
                        prefix={
                          <Img
                            className="mt-0.5 mb-auto h-5 mr-4"
                            src="images/img_lock1.svg"
                            alt="lock"
                          />
                        }
                        type="password"
                        value={password}
                        onChange={(v) => {
                          setPassword(v);
                        }}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-12 items-center justify-start w-full">
                <button
                  className="bg-indigo-900 cursor-pointer font-bold py-[19px] rounded-[28px] text-center text-sm text-white-A700 w-full"
                  onClick={login}
                  type="button"
                >
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
