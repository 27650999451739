import React from "react";
import { IoMdClose } from "react-icons/io";
import { Button, Img, Input, Line, List, SelectBox, Text } from "components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiProvider } from "apiProvider/api_provider";
import { IUser, Permission } from "interfaces/user_interfaces";
import { IGlobalProps } from "Routes";
import { Constants } from "constants/constants";

const Users = (props: IGlobalProps) => {
  const [userList, setUserList] = React.useState<IUser[]>([]);

  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [permission, setPermission] = React.useState<string>("");

  const [selectedEditId, setSelectedEditId] = React.useState<string>("");
  const [editPermission, setEditPermission] = React.useState<string>("");

  const [refresh, setRefresh] = React.useState(true);

  const addUser = async () => {
    if (name == "" || email == "" || password == "" || permission == "") {
      toast("Fill all fields!");
      return;
    }
    let data = {
      name: name,
      email: email,
      password: password,
      permission: permission,
    };

    ApiProvider.post(JSON.stringify(data), "api/add-user").then((res) => {
      if (res == true) {
        clearAllFields();
        toast("User added successfully");
        setRefresh(!refresh);
      } else {
        toast("User not added");
      }
    });
  };

  const editUser = async () => {
    if (editPermission == "") {
      toast("Select permissions!");
      return;
    }
    let data = {
      permission: editPermission,
    };

    /// ADD update permissions here
    ApiProvider.put(`api/update-user/${selectedEditId}`, JSON.stringify(data)).then((res) => {
      if (res == true) {
        toast("User permissions updated successfully");
        setRefresh(!refresh);
      } else {
        toast("User permissions not updated");
      }
      setEditPopup(false);
    });
  };

  const getUsers = async () => {
    ApiProvider.get("api/get-all-users").then((response) => {
      let u: IUser[] = response.users.map((v, i) => {
        let u1: IUser = {
          id: v._id,
          userId: v.user_id,
          name: v.name,
          email: v.email,
          password: v.password,
          permission: JSON.parse(v.permission),
        };
        return u1;
      });
      setUserList(u);
    });
  };

  const clearAllFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPermission("");
  };

  React.useEffect(() => {
    getUsers();
  }, [refresh]);

  // const customerListItem = (border: boolean, data: IUser) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[10%] sm__w_mobile_100"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.userId}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.name}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[30%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.email}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >

  //       </Text>
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         onClick={() => {
  //           setSelectedEditId(data.id);
  //           setEditPopup(true);
  //         }}
  //       >
  //         Edit
  //       </Button>
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         type="button"
  //         onClick={() => {
  //           if (window.confirm("Are you sure! This is irreversible.")) {
  //             ApiProvider.delete(`api/delete-user/${data.id}`).then((v) => {
  //               if (v == true) {
  //                 toast("User Deleted");
  //                 setRefresh(!refresh);
  //               } else {
  //                 toast("User deletion failed!");
  //               };
  //             })
  //           }
  //         }}
  //       >
  //         Delete
  //       </Button>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (border: boolean, data: IUser) => (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={data.id}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.userId}</th>
      <td className="px-6 py-4">{data.name}</td>
      <td className="px-6 py-4">{data.email}</td>
      <td className="px-6 py-4">{data.permission.map((v, i) => {
        let index;
        permissionValueList.filter((a, i) => {
          if(v == a){
            index = i;
            return v === a;
          }
          return false;
        })
        if(i == data.permission.length - 1){
          return permissionLabelList[index];
        } else {
          return `${permissionLabelList[index]} & `;
        }
      })}</td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
          color="indigo_500"
          size="xs"
          variant="outline"
          onClick={() => {
            setSelectedEditId(data.id);
            setEditPopup(true);
          }}
        >
          Edit
        </Button>
      </td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
          color="indigo_500"
          size="xs"
          variant="outline"
          type="button"
          onClick={() => {
            if (window.confirm("Are you sure! This is irreversible.")) {
              ApiProvider.delete(`api/delete-user/${data.id}`).then((v) => {
                if (v == true) {
                  toast("User Deleted");
                  setRefresh(!refresh);
                } else {
                  toast("User deletion failed!");
                };
              })
            }
          }}
        >
          Delete
        </Button>
      </td>
    </tr>
  );

  const permissionLabelList = Object.keys(Permission);
  const permissionValueList = Object.values(Permission);

  const [editPopup, setEditPopup] = React.useState(false);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
                style={{
                  filter: Constants.imgFilter
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Users
            </Text>
          </div>
        </div>
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-[98%] md:w-full">
          <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[66%] md:w-full">
            <Text
              className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
              size="txtInterSemiBold22"
            >
              Add a user
            </Text>
            <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full">
              <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Name
                  </Text>
                  <Input
                    name="Group195"
                    className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                    wrapClassName="border border-gray-300 border-solid w-full"
                    shape="round"
                    color="white_A700"
                    value={name}
                    onChange={(v) => {
                      setName(v);
                    }}
                  ></Input>
                </div>
                <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Email
                  </Text>
                  <Input
                    name="Group195 One"
                    className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                    wrapClassName="border border-gray-300 border-solid w-full"
                    shape="round"
                    color="white_A700"
                    value={email}
                    onChange={(v) => {
                      setEmail(v);
                    }}
                  ></Input>
                </div>
              </div>
              <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Password
                  </Text>
                  <Input
                    name="Group195 One"
                    className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                    wrapClassName="border border-gray-300 border-solid w-full"
                    shape="round"
                    color="white_A700"
                    value={password}
                    onChange={(v) => {
                      setPassword(v);
                    }}
                  ></Input>
                </div>
                <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start w-[48%] md:w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Permission Level
                  </Text>
                  <SelectBox
                    className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                    placeholderClassName="text-bluegray-900"
                    indicator={
                      <Img
                        className="h-6 mr-[19px] w-6"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                        style={{
                          filter: Constants.imgFilter
                        }}
                      />
                    }
                    isMulti={true}
                    name="date"
                    options={
                      permissionLabelList.map((v, i) => {
                        return {
                          label: v,
                          value: permissionValueList[i],
                        };
                      })}
                    isSearchable={false}
                    shape="round"
                    color="white_A700"
                    size="xs"
                    variant="fill"
                    onChange={(v) => {
                      setPermission(JSON.stringify(v));
                    }}
                  />
                </div>
              </div>
              <Button
                className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                color="indigo_600"
                size="md"
                variant="fill"
                type="button"
                onClick={addUser}
              >
                Add User
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <Text
              className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
              size="txtInterSemiBold22"
            >
              Users Overview
            </Text>
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">Id</th>
                      <th scope="col" className="px-6 py-3">Name</th>
                      <th scope="col" className="px-6 py-3">Email</th>
                      <th scope="col" className="px-6 py-3">Permission Level</th>
                      <th scope="col" className="px-6 py-3">Edit</th>
                      <th scope="col" className="px-6 py-3">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((e, i) =>
                      customerListItem(i != userList.length - 1, e)
                    )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%] sm:overflow-x-scroll md:overflow-x-scroll"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Name
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[30%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Email
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Permission Level
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Edit
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {userList.map((e, i) =>
                  customerListItem(i != userList.length - 1, e)
                )}
              </List> */}
            </div>
          </div>
        </div>
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          editPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Edit Permission
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setEditPopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Select new permissions for the user. The old permission does not include in this list you will add
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Permission
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: Constants.imgFilter
                          }}
                        />
                      }
                      isMulti={true}
                      options={
                        permissionLabelList.map((v, i) => {
                          return {
                            label: v,
                            value: permissionValueList[i],
                          };
                        })}
                      isSearchable={false}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setEditPermission(JSON.stringify(value));
                      }}
                    />
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={editUser}
                >
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Users;
