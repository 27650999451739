import { IDashboardFinanceTitle } from "interfaces/dashboard_finance_insight";
import {
  DurationType,
  FinanceStatus,
} from "interfaces/micro_finance_interfaces";

export class Constants {
  static imageBaseUrl: string = "https://storage.googleapis.com/vsm-v1/";

  static totalTitles: IDashboardFinanceTitle = {
    borrowedTitle: "Total Loan Amount",
    loanPaidTitle: "Per Month Interest",
    balanceTitle: "Amount Paid",
  };

  static chitsTitles: IDashboardFinanceTitle = {
    borrowedTitle: "Chits Loan Amount",
    loanPaidTitle: "Per Month Interest",
    balanceTitle: "Amount Paid",
  };

  static microFinanceTitles: IDashboardFinanceTitle = {
    borrowedTitle: "Micro Finance Loan Amount",
    loanPaidTitle: "Per Unit Interest",
    balanceTitle: "Amount Paid",
  };

  static generalFinanceTitles: IDashboardFinanceTitle = {
    borrowedTitle: "General Finance Loan Amount",
    loanPaidTitle: "Per Unit Interest",
    balanceTitle: "Amount Paid",
  };

  static formatDate = (inputDate) => {
    try{
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const [year, month, day] = inputDate.split("-");
    const formattedDay = day.padStart(2, "0"); // Ensures two-digit day with leading zero
    const monthName = months[parseInt(month, 10) - 1];
    const formattedDate = `${formattedDay} ${monthName} ${year}`;
    return formattedDate;
  }
  catch(e) {
    return "";
  }
  };

  static addToDates = (
    type: DurationType,
    dateString: string,
    times: number
  ): string => {
    switch (type) {
      case DurationType.MONTHS: {
        const date = new Date(dateString);
        date.setUTCMonth(date.getUTCMonth() + times);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
        const day = date.getUTCDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      case DurationType.WEEKS: {
        const date = new Date(dateString);
        date.setDate(date.getDate() + times * 7);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
      case DurationType.DAYS: {
        const date = new Date(dateString);
        date.setDate(date.getDate() + times);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
      default:
        return dateString;
    }
  };

  static formatDateByTimeStamps = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  static imgFilter = "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)";

  static capitalizeFirstLetter = (string) : string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

static getDateDifference = (date : string, type : DurationType) : number => {
  const date1 = new Date(date);
  const date2 = new Date(); 
  const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
  const diffInWeeks = Math.floor(diffInDays / 7);

  switch (type) {
    case DurationType.DAYS:
      return diffInDays;
    case DurationType.MONTHS:
      return diffInMonths;
    case DurationType.WEEKS:
      return diffInWeeks;
    default:
      return 0;
  }
}
}

export enum SideBarType {
  Dashboard,
  ChitFinance,
  Auctions,
  MicroFinance,
  GeneralFinance,
  Customers,
  Payments,
  Penalty,
  Borrowings,
  Users,
  Support,
}

export interface IDropdown{
  label : string;
  value : string;
}