import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Input, Line, List, SelectBox, Switch, Text } from "components";
import { CloseSVG } from "../../assets/images";
import { IoMdClose } from "react-icons/io";
import {
  EmiStatus,
  IAuction,
  IChit,
  ICustomerChit,
  IEmi,
} from "interfaces/chit_interfaces";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ICustomer } from "interfaces/customer";
import { Constants } from "constants/constants";
import { IMFPayment } from "interfaces/micro_finance_interfaces";
import { IGlobalProps } from "Routes";
import { Permission, PermissionClass } from "interfaces/user_interfaces";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import numWords from 'num-words';

const ChitFinance = (props: IGlobalProps) => {
  const pdfRef = useRef();
  const [refresh, setRefresh] = React.useState(true);
  const [changePopup, setChangePopup] = React.useState(false);
  const [editPopup, setEditPopup] = React.useState(false);
  const [addPopup, setAddPopup] = React.useState(false);
  const [chitsList, setChitsList] = React.useState<IChit[]>([]);
  const [customerList, setCustomerList] = React.useState<ICustomer[]>(null);
  const [chitName, setChitName] = React.useState<string>("");
  const [chitAmount, setChitAmount] = React.useState<string>("");
  const [commission, setCommission] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>("");
  const [months, setMonths] = React.useState<string>("");
  const [selectedCustomersList, setSelectedCustomersList] = React.useState<
    string[]
  >([]);
  const [customerChits, setCustomerChits] = React.useState<Array<number>>([]);
  const [dropdownCustomerList, setDropdownCustomerList] = React.useState([]);

  const [dropdownMonthsList, setDropdownMonthsList] = React.useState([]);
  const [selectedMonth, setSelectedMonth] = React.useState<number>();

  const [selectedChit, setSelectedChit] = React.useState<IChit>(null);
  const [active, setActive] = React.useState<boolean>(true);

  const [changeCustomer, setChangeCustomer] = React.useState<number>(null);
  const [selectedChangeCustomer, setSelectedChangeCustomer] = React.useState<ICustomer>(null);
  const [editStartDate, setEditStartDate] = React.useState<string>("");

  const fetchCustomers = async () => {
    ApiProvider.getAllCustomers(`api/fetch-all-customer?search=`).then((response) => {
      setCustomerList(response);
      let data = response.map((v, i) => {
        return {
          label: v.firstName + " " + v.lastName,
          value: JSON.stringify(v),
        };
      });
      setDropdownCustomerList(data);
    });
  };

  const downloadPDF = async () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('chit-finance.pdf');
    });
  };

  const fetchChits = () => {
    ApiProvider.getAllChits(active).then((res) => {
      setChitsList(res);
    });
  };

  const addChit = async () => {
    console.log(selectedCustomersList);
    if (
      chitName == "" ||
      chitAmount == "" ||
      commission == "" ||
      startDate == "" ||
      months == ""
    ) {
      toast("Fill all fields!");
      return;
    }
    if (selectedCustomersList.length == 0) {
      toast("Select customers!");
      return;
    }
    let t = customerChits.reduce((sum, v) => sum + parseInt(v.toString()), 0);
    if (parseInt(months) !== t) {
      toast("Number of chits does not sum upto number of months");
      return;
    }

    let arr = new Array(parseInt(months));
    arr.fill(0);

    let details: IChit = {
      chit_name: chitName,
      chit_amount: parseInt(chitAmount),
      start_date: startDate,
      number_of_months: parseInt(months),
      commission: parseInt(commission),
      customers: getCustomers(arr),
      auctions: getAuctions(arr),
      emis: getEmis(arr),
    };

    ApiProvider.addChit(details).then((res) => {
      if (res == true) {
        setAddPopup(false);
        toast("Chit added successfully");
        setRefresh(!refresh);
      } else {
        toast("Chit not added");
      }
    });
  };

  const getCustomers = (arr: Array<number>): ICustomerChit[] => {
    let emiStatusList: EmiStatus[] = arr.map((v, i) => {
      return EmiStatus.PENDING;
    });
    let amountPaid: IMFPayment[] = arr.map((v, i) => {
      return {
        amount: 0,
        received_date: "",
      };
    });
    let data: ICustomerChit[] = selectedCustomersList.map((v, i) => {
      let customer: ICustomer = JSON.parse(selectedCustomersList[i]);
      return {
        id: customer.id,
        customerId: customer.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.phoneNumber,
        chitShare: customerChits[i],
        amountPaid: amountPaid,
        emiStatus: emiStatusList,
      };
    });
    return data;
  };

  const getAuctions = (arr: Array<number>): IAuction[] => {
    let auctionsList: IAuction[] = arr.map((v, i) => {
      return {
        auction_id: `AUCTION-${String(i + 1).padStart(
          3 - (i + 1).toString().length,
          "0"
        )}`,
        auctionAmount: null,
        distributionAmount: null,
        distributionDate: null,
        auctionDate: null,
        auctionWinner: null,
        index: i,
      };
    });
    return auctionsList;
  };

  const getEmis = (arr: Array<number>): IEmi[] => {
    const totalChits = customerChits.reduce(
      (partialSum, a) => partialSum + parseInt(a.toString()),
      0
    );
    let emi: number = parseInt(chitAmount) / totalChits;
    let emiList: IEmi[] = arr.map((v, i) => {
      return {
        emiAmount: emi,
        emiDate: null,
        index: i,
      };
    });
    return emiList;
  };

  const updateChit = async (body) => {
    await ApiProvider.updateChit(
      JSON.stringify(body),
      selectedChit.id
    ).then((res) => {
      console.log(res);
    });
  }

  React.useEffect(() => {
    fetchChits();
    fetchCustomers();
  }, [refresh, active]);

  // const customerListItem = (border: boolean, index: number) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full" key={index}>
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[100%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 sm__w_mobile_100 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.customers[index].customerId}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 sm__w_mobile_100 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.customers[index].firstName +
  //           " " +
  //           selectedChit.customers[index].lastName}
  //       </Text>
  //       <Text
  //         className="ml-[20px] md:ml-[0] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.customers[index].phoneNumber}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[5%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.customers[index].chitShare}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${selectedChit.customers[index].amountPaid[selectedMonth].amount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${parseFloat(
  //           (
  //             selectedChit.emis[selectedMonth].emiAmount *
  //             selectedChit.customers[index].chitShare
  //           ).toFixed(2)
  //         )}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.customers[index].emiStatus[selectedMonth]}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.auctions[selectedMonth].distributionDate != null
  //           ? Constants.formatDate(
  //             selectedChit.auctions[selectedMonth].distributionDate
  //           )
  //           : "-"}
  //       </Text>
  //       <div className="flex flex-row items-start md:ml-[0] ml-[20px] justify-start w-[10%]">
  //         <Button
  //           className="cursor-pointer font-medium leading-[normal] min-w-[70px] rounded-[17.5px] text-[15px] text-center"
  //           color="indigo_500"
  //           size="xs"
  //           variant="outline"
  //           type="button"
  //           onClick={() => {
  //             setChangeCustomer(index);
  //             setChangePopup(true);
  //           }}
  //         >
  //           Change
  //         </Button>
  //       </div>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (border: boolean, index: number) => (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={index}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{selectedChit.customers[index].customerId}</th>
      <td className="px-6 py-4"> {selectedChit.customers[index].firstName +
        " " +
        selectedChit.customers[index].lastName}</td>
      <td className="px-6 py-4">{selectedChit.customers[index].phoneNumber}</td>
      <td className="px-6 py-4">{selectedChit.customers[index].chitShare}</td>
      <td className="px-6 py-4">{`₹${selectedChit.customers[index].amountPaid[selectedMonth].amount}`}</td>
      <td className="px-6 py-4">{`₹${parseFloat(
        (
          selectedChit.emis[selectedMonth].emiAmount *
          selectedChit.customers[index].chitShare
        ).toFixed(2)
      )}`}</td>
      <td className="px-6 py-4">{selectedChit.customers[index].emiStatus[selectedMonth]}</td>
      <td className="px-6 py-4">{selectedChit.auctions[selectedMonth].distributionDate != null
        ? Constants.formatDate(
          selectedChit.auctions[selectedMonth].distributionDate
        )
        : "-"}</td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[70px] rounded-[17.5px] text-[15px] text-center"
          color="indigo_500"
          size="xs"
          variant="outline"
          type="button"
          onClick={() => {
            setChangeCustomer(index);
            setChangePopup(true);
          }}
        >
          Change
        </Button>
      </td>
    </tr>
  );

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full sm:h-[100%] md:h-[100%] h-[80px]">
          <div className="flex sm:flex-row md:flex-col flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
                style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Chit Finance
            </Text>
            <Switch
              onColor="#359766"
              offColor="#e3e4e8"
              onHandleColor="#ffffff"
              offHandleColor="#ffffff"
              value={active}
              className="w-[15%] mr-[20px]"
              onChange={(v) => {
                setActive(v);
              }}
            />
            <div className="mobile__d-none">
              <SelectBox
                className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-[160px]"
                placeholderClassName="text-bluegray-900"
                indicator={
                  <Img
                    className="h-6 mr-[19px] w-6"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                    style={{
                      filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                    }}
                  />
                }
                isMulti={false}
                options={chitsList.map((v, i) => {
                  return { label: v.chit_name, value: JSON.stringify(v) };
                })}
                isSearchable={false}
                shape="round"
                color="white_A700"
                size="xs"
                variant="fill"
                onChange={(value) => {
                  let data: IChit = JSON.parse(value);
                  setSelectedChit(data);
                  let arr = new Array(data.number_of_months);
                  arr.fill(0);
                  let monthsList = arr.map((v, i) => {
                    return { label: `Auction ${i + 1}`, value: i };
                  });
                  setDropdownMonthsList(monthsList);
                  if (monthsList.length > 0) {
                    setSelectedMonth(0);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row mobile__d-block w-[100%]">
            <SelectBox
              className="border w-[100%] border-gray-300 border-solid leading-[normal] text-[15px] text-left"
              placeholderClassName="text-bluegray-900"
              indicator={
                <Img
                  className="h-6 mr-[19px] w-6"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                  style={{
                    filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                  }}
                />
              }
              isMulti={false}
              options={chitsList.map((v, i) => {
                return { label: v.chit_name, value: JSON.stringify(v) };
              })}
              isSearchable={false}
              shape="round"
              color="white_A700"
              size="xs"
              variant="fill"
              onChange={(value) => {
                let data: IChit = JSON.parse(value);
                setSelectedChit(data);
                let arr = new Array(data.number_of_months);
                arr.fill(0);
                let monthsList = arr.map((v, i) => {
                  return { label: `Auction ${i + 1}`, value: i };
                });
                setDropdownMonthsList(monthsList);
                if (monthsList.length > 0) {
                  setSelectedMonth(0);
                }
              }}
            />
          </div>
          <div className="flex flex-row mobile__d-block w-[100%]">
            {selectedChit && (
              <SelectBox
                className="border w-[100%] border-gray-300 border-solid leading-[normal] text-[15px] text-left"
                placeholderClassName="text-bluegray-900"
                indicator={
                  <Img
                    className="h-6 mr-[19px] w-6"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                    style={{
                      filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                    }}
                  />
                }
                isMulti={false}
                options={dropdownMonthsList}
                isSearchable={false}
                shape="round"
                color="white_A700"
                size="xs"
                variant="fill"
                onChange={(value) => {
                  setSelectedMonth(value);
                }}
              />
            )}
          </div>
          <div className="flex flex-row">
            <div className="mobile__d-none">
              {selectedChit && (
                <SelectBox
                  className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-[160px]"
                  placeholderClassName="text-bluegray-900"
                  indicator={
                    <Img
                      className="h-6 mr-[19px] w-6"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                      style={{
                        filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                      }}
                    />
                  }
                  isMulti={false}
                  options={dropdownMonthsList}
                  isSearchable={false}
                  shape="round"
                  color="white_A700"
                  size="xs"
                  variant="fill"
                  onChange={(value) => {
                    setSelectedMonth(value);
                  }}
                />
              )}
            </div>
            {selectedChit && (<Button
              className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
              shape="circle"
              color="gray_102"
              size="xs"
              variant="fill"
              onClick={downloadPDF}
            >
              <img
                className="h-[30px]"
                src="images/pdf_export.png"
                alt="PDF Export"
                style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}
              />
            </Button>)}
            {selectedChit != null && PermissionClass.hasPermission(Permission.CHITWRITE) && (
              <Button
                className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
                shape="circle"
                color="gray_102"
                size="xs"
                variant="fill"
                onClick={() => setEditPopup(true)}
              >
                <Img
                  className="h-[20px]"
                  src="images/edit_icon.svg"
                  alt="user One"
                  style={{
                    filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                  }}
                />
              </Button>
            )}
            {PermissionClass.hasPermission(Permission.CHITWRITE) && <Button
              className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
              shape="circle"
              color="gray_102"
              size="xs"
              variant="fill"
              onClick={() => setAddPopup(true)}
            >
              <Img
                className="h-[30px]"
                src="images/img_add.png"
                alt="user One"
                style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}
              />
            </Button>}
            {PermissionClass.hasPermission(Permission.ADMIN) && selectedChit != null &&
            <img
                className="flex h-[47px] items-center justify-center rounded-[50%] w-[47px] ml-[20px]"
                src="images/bin.png"
                alt="user One"
                onClick={() => {
                  if(window.confirm("Are you sure you want to delete this chit?")){
                    ApiProvider.delete(`api/delete-chit/${selectedChit.id}`).then((v) => {
                      if(v == true){
                        toast("Chit Deleted");
                        window.location.reload();
                      } else {
                        toast("Chit not deleted! Try again later..")
                      }
                    })
                    
                  }
                }}
              />}
          </div>
        </div>
        {selectedChit != null ? (
          <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full" ref={pdfRef}>
            <List
              className="sm:flex-col flex-row gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-center w-full"
              orientation="horizontal"
            >
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start w-[99%] md:w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_user_70X70.svg"
                      alt="user One"
                      style={{
                        filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Id
                    </Text>
                    <Text
                      className="text-bluegray-900 text-xl"
                      size="txtInterSemiBold20Bluegray900"
                    >
                      {selectedChit.chit_id}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start my-1 w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_reply.svg"
                      alt="bag"
                      style={{
                        filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-bluegray-900"
                      size="txtInterRegular16Bluegray400"
                    >
                      {selectedChit.start_date &&
                        Constants.formatDate(selectedChit.start_date)}
                    </Text>
                    <Text
                      className="text-bluegray-900"
                      size="txtInterRegular16Bluegray400"
                    >
                      {selectedChit.end_date &&
                        Constants.formatDate(selectedChit.end_date)}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_graph1.svg"
                      alt="graphOne"
                      style={{
                        filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Value
                    </Text>
                    <Text
                      className="text-bluegray-900 text-xl"
                      size="txtInterSemiBold20Bluegray900"
                    >
                      {`₹${selectedChit.chit_amount}`}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-3 items-center justify-start my-1 w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_airplane.svg"
                      alt="airplane"
                      style={{
                        filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Commission
                    </Text>
                    <Text
                      className="text-bluegray-900 text-lg"
                      size="txtInterSemiBold18"
                    >
                      {`${selectedChit.commission}%`}
                    </Text>
                  </div>
                </div>
              </div>
            </List>
            <div className="flex flex-col gap-[15px] items-start justify-start w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Customers Overview
              </Text>
              <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">Id</th>
                        <th scope="col" className="px-6 py-3">Name</th>
                        <th scope="col" className="px-6 py-3">Mobile</th>
                        <th scope="col" className="px-6 py-3">Chits</th>
                        <th scope="col" className="px-6 py-3">Amount Paid</th>
                        <th scope="col" className="px-6 py-3">Emi</th>
                        <th scope="col" className="px-6 py-3">Emi Status</th>
                        <th scope="col" className="px-6 py-3">Emi Due Date</th>
                        <th scope="col" className="px-6 py-3">Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedChit &&
                        selectedChit.customers.map((e, i) =>
                          customerListItem(
                            i != selectedChit.customers.length - 1,
                            i
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                {/* <List
                  className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                  orientation="vertical"
                >
                  <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                    <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400 table__finance__id_col w-[15%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Id
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Name
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Mobile
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[5%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Chits
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Amount Paid
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Emi
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Emi Status
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Emi Due Date
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Change
                      </Text>
                      <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[2%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Pay
                    </Text>
                    </div>
                    <Line className="bg-gray-103 h-px w-full" />
                  </div>
                  {selectedChit &&
                    selectedChit.customers.map((e, i) =>
                      customerListItem(
                        i != selectedChit.customers.length - 1,
                        i
                      )
                    )}
                </List> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6 items-center justify-center w-[98%] md:w-full h-[70vh]">
            <div className="bg-white-A700 flex flex-col items-center justify-end p-[27px] sm:px-5 rounded-[25px] w-[50%] sm__w_mobile_100">
              <div className="flex flex-col gap-[27px] items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start rounded-[10px] w-full">
                  <div className="flex flex-col gap-3 items-center justify-start w-full">
                    <div className="flex sm:flex-row flex-row items-end justify-between w-full">
                      <div className="bg-indigo-600 h-[135px] sm:mt-0 mt-[99px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[234px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[106px] sm:mt-0 mt-32 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[186px] sm:mt-0 mt-12 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[102px] sm:mt-0 mt-[132px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[139px] sm:mt-0 mt-[95px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[212px] sm:mt-0 mt-[22px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[123px] sm:mt-0 mt-[111px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[150px] sm:mt-0 mt-[84px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[214px] sm:mt-0 mt-5 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[158px] sm:mt-0 mt-[76px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[105px] sm:mt-0 mt-[129px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[179px] sm:mt-0 mt-[55px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[216px] sm:mt-0 mt-[18px] rounded-[10px] w-[5%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-center w-[93%] md:w-full">
                      <Text
                        className="text-bluegray-600 text-l text-center"
                        size="txtInterRegular14"
                      >
                        To view chit details select one from the dropdown above
                        or create a new chit
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between sm__w_mx_mobile_100 max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Chit
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal"
                  size="txtInterRegular16Bluegray400"
                >
                  To create a chit provide data for all the fields. After you
                  select all the customers participating in the chit after that
                  you get option to provide share of chits to each customer. By
                  default each client chit will be 1.
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Name
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={chitName}
                      onChange={(e) => {
                        setChitName(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Amount
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={chitAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setChitAmount(e);
                        }
                      }}
                    ></Input>
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Amount in words = {`${Constants.capitalizeFirstLetter(numWords(parseInt(chitAmount)))}`}
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Comission(%)
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={commission}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setCommission(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Start Date
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Months
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={months}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setMonths(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Members
                    </Text>
                    <SelectBox
                      menuPlacement="top"
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                          }}
                        />
                      }
                      isMulti={true}
                      options={dropdownCustomerList}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setSelectedCustomersList(value);
                        var arr = new Array(value.length);
                        arr.fill(1);
                        setCustomerChits(arr);
                      }}
                    />
                  </div>
                </div>
                {customerChits.length > 0 && (
                  <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        Selected Customers
                      </Text>
                    </div>
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        Chits
                      </Text>
                    </div>
                  </div>
                )}
                {selectedCustomersList.map((v, i) => {
                  let data: ICustomer = JSON.parse(selectedCustomersList[i]);
                  return (
                    <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                        <Text
                          className="text-[18px] text-bluegray-900 sm:text-lg md:text-xl"
                          size="txtInterSemiBold22"
                        >
                          {data.firstName + " " + data.lastName}
                        </Text>
                      </div>
                      <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                        <Input
                          name="Group195"
                          className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                          wrapClassName="border border-gray-300 border-solid w-full"
                          shape="round"
                          color="white_A700"
                          value={customerChits[i]}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e === "" || re.test(e)) {
                              let newChitsData = [...customerChits];
                              newChitsData[i] = e;
                              setCustomerChits(newChitsData);
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  );
                })}
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addChit}
                >
                  Add Chit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {selectedChit != null && <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          editPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between sm__w_mx_mobile_100 max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Edit Chit
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setEditStartDate("");
                        setEditPopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Once you update start date end date is automatically updated
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Start Date
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      type="date"
                      onChange={(e) => {
                        setEditStartDate(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={() => {
                    if (editStartDate === "") {
                      toast("Select Date");
                      return;
                    }
                    updateChit({
                      start_date: editStartDate,
                      end_date: ApiProvider.addMonthsToDate(
                        editStartDate,
                        selectedChit.number_of_months - 1
                      )
                    }).then(() => {
                      window.location.reload();
                    })
                  }}
                >
                  Update Chit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>}
      {selectedChit != null && customerList != null && <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          changePopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Change Defaulter Customer
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setChangeCustomer(null);
                        setSelectedChangeCustomer(null);
                        setChangePopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Select new custome on this place. The old data will be transferred to new customer
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Customer
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: Constants.imgFilter
                          }}
                        />
                      }
                      isMulti={false}
                      options={
                        customerList.filter((v, i) => {
                          return selectedChit.customers.filter((c, i) => {
                            return v.id === c.id
                          }).length === 0
                        }).map((v, i) => {
                          return {
                            label: v.firstName + " " + v.lastName,
                            value: JSON.stringify(v),
                          };
                        })}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setSelectedChangeCustomer(JSON.parse(value));
                      }}
                    />
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={async () => {
                    if (selectedChangeCustomer === null || changeCustomer === null) {
                      toast("Select a customer");
                      return;
                    }
                    let chitCustomers: ICustomerChit[] = selectedChit.customers;
                    let changeCustomerChit: ICustomerChit = chitCustomers[changeCustomer];
                    changeCustomerChit.customerId = selectedChangeCustomer.customerId;
                    changeCustomerChit.firstName = selectedChangeCustomer.firstName;
                    changeCustomerChit.lastName = selectedChangeCustomer.lastName;
                    changeCustomerChit.id = selectedChangeCustomer.id;
                    changeCustomerChit.phoneNumber = selectedChangeCustomer.phoneNumber;
                    chitCustomers[changeCustomer] = changeCustomerChit;
                    updateChit({ customers: JSON.stringify(chitCustomers) }).then(() => {
                      setChangePopup(false);
                      setRefresh(!refresh);
                    });

                  }}
                >
                  Change
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>}
    </>
  );
};

export default ChitFinance;
