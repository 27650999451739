export interface IUser {
  id?: string;
  userId?: string;
  name: string;
  email: string;
  password: string;
  permission: Permission[];
}

export enum Permission {
  ADMIN = "11",
  AGENT = "22",
  GUEST = "33",
  CHITREAD = "41",
  CHITWRITE = "42",
  AUCTIONREAD = "43",
  AUCTIONWRITE = "44",
  MFREAD = "51",
  MFWRITE = "52",
  GFREAD = "61",
  GFWRITE = "62",
  CUSTOMERSREAD = "71",
  CUSTOMERSWRITE = "72",
  PAYMENTREAD = "81",
  PAYMENTWRITE = "82",
  PENALTYREAD = "91",
  PENALTYWRITE = "92",
}

export class PermissionClass {
  static hasPermission = (permission: Permission) => {
    let userPermissions: Array<string> = [];
    let data = localStorage.getItem("vsmaccess");
    let list = JSON.parse(data);

    try {
      list.forEach((v, i) => {
        userPermissions.push(v);
      });
    } catch (e) {
      console.log(e);
    }

    switch (permission) {
      case Permission.ADMIN: {
        return userPermissions.includes(Permission.ADMIN);
      }

      case Permission.AGENT: {
        return userPermissions.includes(Permission.AGENT);
      }

      case Permission.GUEST: {
        return userPermissions.includes(Permission.GUEST);
      }

      case Permission.CHITREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.CHITREAD)
        );
      }

      case Permission.CHITWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.CHITWRITE)
        );
      }

      case Permission.AUCTIONREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AUCTIONREAD)
        );
      }

      case Permission.AUCTIONWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AUCTIONWRITE)
        );
      }

      case Permission.MFREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.MFREAD)
        );
      }

      case Permission.MFWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.MFWRITE)
        );
      }

      case Permission.GFREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.GFREAD)
        );
      }

      case Permission.GFWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GFWRITE)
        );
      }

      case Permission.CUSTOMERSREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.CUSTOMERSREAD)
        );
      }

      case Permission.CUSTOMERSWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.CUSTOMERSREAD)
        );
      }

      case Permission.PAYMENTREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.PAYMENTREAD)
        );
      }

      case Permission.PAYMENTWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.PAYMENTWRITE)
        );
      }

      case Permission.PENALTYREAD: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.GUEST) ||
          userPermissions.includes(Permission.PENALTYREAD)
        );
      }

      case Permission.PENALTYWRITE: {
        return (
          userPermissions.includes(Permission.ADMIN) ||
          userPermissions.includes(Permission.AGENT) ||
          userPermissions.includes(Permission.PENALTYWRITE)
        );
      }
      default: return false;
    }
  };
}
