import { ICustomer } from "./customer";
import { FinanceType, IFinance } from "./payment_interfaces";

export interface IPenaltyApiResponse {
  total : number;
  penalties : IPenalty[];
}

export interface IPenalty {
  id?: string;
  penalty_id: string;
  customer: ICustomer;
  status: OtherStatus;
  finance: string;
  type: IFinance;
  amount: number;
  remarks: string;
  created: Date;
}

export interface ILendor {
  id?: string;
  lendorId?: string;
  firstName: string;
  lastName: string;
  phoneNumber: number;
  address: string;
}

export interface IBorrowing {
  id?: string;
  borrowing_id: string;
  lendor: ILendor;
  interest: number;
  type: string;
  loanAmount: number;
  receivedAmount: number;
  receivedDate: number;
  paidAmount : number;
  status : OtherStatus;
}

export enum OtherStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}
