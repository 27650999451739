import { ICustomer } from "./customer";

export interface IPaymentApiResponse {
  total : number;
  message : string;
  payments : IPayment[];
}
export interface IPayment {
  id?: string;
  paymentId?: string;
  customer: ICustomer;
  chitFinances: IFinance[];
  microFinances: IFinance[];
  generalFinances: IFinance[];
  totalAmount : number;
  remarks : string;
  createdAt? : Date;
}

export interface IFinance {
  financeId: string;
  customId: string;
  month: number;
  amount: number;
  type: FinanceType;
  financeStartDate: string;
  financeName: string;
}

export enum FinanceType {
  CHITFINANCE = "Chit Finance",
  MICROFINANCE = "Micro Finance",
  GENERALFINANCE = "General Finance",
}
