import React from "react";
import { IoMdClose } from "react-icons/io";
import { ICustomer } from "interfaces/customer";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  SelectBox,
  Switch,
  Text,
} from "components";
import { IPenalty, OtherStatus } from "interfaces/remaining_interfaces";
import { Constants, IDropdown } from "constants/constants";
import { FinanceType } from "interfaces/payment_interfaces";
import { IGlobalProps } from "Routes";
import { Permission, PermissionClass } from "interfaces/user_interfaces";
import numWords from 'num-words';
const Penalty = (props: IGlobalProps) => {
  const [refresh, setRefresh] = React.useState(true);

  const [amount, setAmount] = React.useState<string>("");
  const [finance, setFinance] = React.useState<string>("");
  const [remarks, setRemarks] = React.useState<string>("");

  const [penalties, setPenalties] = React.useState<IPenalty[]>([]);

  const [customerList, setCustomerList] = React.useState<ICustomer[]>(null);
  const [dropdownCustomerList, setDropdownCustomerList] = React.useState([]);

  const [selectedCustomer, setSelectedCustomer] = React.useState<string>(null);
  const [customerFinances, setCustomerFinances] = React.useState<IDropdown[]>(
    []
  );

  const [penaltyDetails, setPenaltyDetails] = React.useState(null);

  const [page, setPage] = React.useState<number>(1);
  const [active, setActive] = React.useState<boolean>(true);
  const [totalCount, setTotalCount] = React.useState<number>(null);

  const [editPopup, setEditPopup] = React.useState(false);
  const [addPopup, setAddPopup] = React.useState(false);

  const fetchCustomers = async () => {
    ApiProvider.getAllCustomers(`api/fetch-all-customer?search=`).then((response) => {
      setCustomerList(response);
      let data = response.map((v, i) => {
        return {
          label: v.firstName + " " + v.lastName,
          value: JSON.stringify(v),
        };
      });
      setDropdownCustomerList(data);
    });
  };

  const fetchFinanceDetails = async () => {
    if (selectedCustomer === null) {
      return;
    }

    let finances: IDropdown[] = [];

    ApiProvider.getChitsOfCustomer(selectedCustomer).then((res) => {
      res.forEach((v, i) => {
        finances.push({
          label: v.chit_id,
          value: v.chit_id,
        });
      });
    });

    ApiProvider.getMicroFinancesOfCustomer(selectedCustomer).then((res) => {
      res.forEach((v, i) => {
        finances.push({
          label: v.mf_id,
          value: v.mf_id,
        });
      });
    });

    ApiProvider.getGeneralFinancesOfCustomer(selectedCustomer).then((res) => {
      res.forEach((v, i) => {
        finances.push({
          label: v.gf_id,
          value: v.gf_id,
        });
      });
    });

    setCustomerFinances(finances);
  };

  const clearFields = async () => {
    setAmount("");
    setSelectedCustomer(null);
    setCustomerFinances([]);
    setFinance("");
    setRemarks("");
  };

  const addPenalty = async () => {
    if (selectedCustomer == null) {
      toast("Please select a customer");
      return;
    }

    if (amount == "" || finance == "" || remarks == "") {
      toast("Fill all fields!");
      return;
    }

    let raw = {
      customer: selectedCustomer,
      status: OtherStatus.PENDING,
      finance: finance,
      type: finance.includes("CHIT")
        ? FinanceType.CHITFINANCE
        : finance.includes("MF")
          ? FinanceType.MICROFINANCE
          : finance.includes("GF")
            ? FinanceType.GENERALFINANCE
            : "Finance Not Determined",
      amount: amount,
      remarks: remarks,
    };

    ApiProvider.post(JSON.stringify(raw), "api/add-penalty").then((res) => {
      if (res == true) {
        setAddPopup(false);
        clearFields();
        setRefresh(!refresh);
        toast("Penalty added");
      } else {
        toast("Penalty addition failed! Please try again later");
      }
    });
  };

  const getAllPenalty = async () => {
    ApiProvider.getPenalties(
      `api/get-all-penalties?page=${page}&size=10&status=${active ? OtherStatus.PENDING : OtherStatus.COMPLETED
      }`
    ).then((res) => {
      setTotalCount(Math.ceil(res.total / 10));
      setPenalties(res.penalties);
    });
  };

  const getPenaltyDetails = async () => {
    ApiProvider.get("api/penalty-details").then((res) => {
      setPenaltyDetails(res);
    });
  };

  React.useEffect(() => {
    getAllPenalty();
  }, [refresh, page, active]);

  React.useEffect(() => {
    fetchCustomers();
    getPenaltyDetails();
  }, [refresh]);

  React.useEffect(() => {
    fetchFinanceDetails();
  }, [refresh, selectedCustomer]);

  // const customerListItem = (border: boolean, data: IPenalty) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.penalty_id}
  //       </Text>
  //       <div className="flex flex-col items-start justify-start w-[20%] md:ml-[0] ml-[20px]">
  //         <Text
  //           className="text-base text-bluegray-900 mb-1"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           {data.customer.customerId}
  //         </Text>
  //         <Text
  //           className="text-base text-bluegray-900 mb-1"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           {`${data.customer.firstName} ${data.customer.lastName}`}
  //         </Text>
  //         <Text
  //           className="text-base text-bluegray-400"
  //           size="txtInterRegular15Bluegray400"
  //         >
  //           {data.customer.phoneNumber}
  //         </Text>
  //       </div>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.finance}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.type}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.amount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {Constants.formatDateByTimeStamps(data.created)}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.remarks}
  //       </Text>
  //       {data.status == OtherStatus.COMPLETED ? (
  //         <Text
  //           className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           Paid
  //         </Text>
  //       ) : (
  //         <Button
  //           className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[15%]"
  //           color="indigo_500"
  //           size="xs"
  //           variant="outline"
  //           onClick={() => {
  //             if (window.confirm(`Mark ${data.penalty_id} as complete`)) {
  //               let raw = {
  //                 status: OtherStatus.COMPLETED,
  //               };

  //               ApiProvider.put(
  //                 `api/update-penalty/${data.id}`,
  //                 JSON.stringify(raw)
  //               ).then((res) => {
  //                 if (res == true) {
  //                   toast(`${data.penalty_id} is marked as complete`);
  //                   setRefresh(!refresh);
  //                 } else {
  //                   toast(
  //                     `${data.penalty_id}'s completion marking failed. Please try again later!`
  //                   );
  //                 }
  //               });
  //             }
  //           }}
  //         >
  //           Mark Paid
  //         </Button>
  //       )}
  //       <Button
  //         className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         onClick={() => {
  //           if (window.confirm(`Delete ${data.penalty_id}`)) {
  //             ApiProvider.delete(`api/delete-penalty/${data.id}`).then(
  //               (res) => {
  //                 if (res == true) {
  //                   toast(`${data.penalty_id} is deleted`);
  //                   setRefresh(!refresh);
  //                 } else {
  //                   toast(
  //                     `${data.penalty_id}'s deletion failed. Please try again later!`
  //                   );
  //                 }
  //               }
  //             );
  //           }
  //         }}
  //       >
  //         Delete
  //       </Button>
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (border: boolean, data: IPenalty) => (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={data.id}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.penalty_id}</th>
      <td className="px-6 py-4">{data.customer.customerId}<br/>{`${data.customer.firstName} ${data.customer.lastName}`}<br/>{data.customer.phoneNumber}</td>
      <td className="px-6 py-4">{data.finance}</td>
      <td className="px-6 py-4">{data.type}</td>
      <td className="px-6 py-4">{`₹${data.amount}`}</td>
      <td className="px-6 py-4">
        {Constants.formatDateByTimeStamps(data.created)}
      </td>
      <td className="px-6 py-4">
        {data.remarks}
      </td>
      <td className="px-6 py-4">
        {data.status == OtherStatus.COMPLETED ? (
          <Text
            className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[100%]"
            size="txtInterRegular16Bluegray900"
          >
            Paid
          </Text>
        ) : (
          <Button
            className="cursor-pointer font-medium leading-[normal] min-w-[100%] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[15%]"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              if (window.confirm(`Mark ${data.penalty_id} as complete`)) {
                let raw = {
                  status: OtherStatus.COMPLETED,
                };

                ApiProvider.put(
                  `api/update-penalty/${data.id}`,
                  JSON.stringify(raw)
                ).then((res) => {
                  if (res == true) {
                    toast(`${data.penalty_id} is marked as complete`);
                    setRefresh(!refresh);
                  } else {
                    toast(
                      `${data.penalty_id}'s completion marking failed. Please try again later!`
                    );
                  }
                });
              }
            }}
          >
            Mark Paid
          </Button>
        )}
      </td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[100%]"
          color="indigo_500"
          size="xs"
          variant="outline"
          onClick={() => {
            if (window.confirm(`Delete ${data.penalty_id}`)) {
              ApiProvider.delete(`api/delete-penalty/${data.id}`).then(
                (res) => {
                  if (res == true) {
                    toast(`${data.penalty_id} is deleted`);
                    setRefresh(!refresh);
                  } else {
                    toast(
                      `${data.penalty_id}'s deletion failed. Please try again later!`
                    );
                  }
                }
              );
            }
          }}
        >
          Delete
        </Button>
      </td>
    </tr>
  );

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex sm:flex-row md:flex-row lg:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                style={{
                  filter: Constants.imgFilter
                }}
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Penalty
            </Text>
          </div>
          <div className="flex flex-row">
            {PermissionClass.hasPermission(Permission.PENALTYWRITE) && <Button
              className="flex h-[50px] items-center justify-center rounded-[50%] w-[50px] ml-[20px]"
              shape="circle"
              color="gray_102"
              size="xs"
              variant="fill"
              onClick={() => setAddPopup(true)}
            >
              <Img
                style={{
                  filter: Constants.imgFilter
                }}
                className="h-[30px]"
                src="images/img_add.png"
                alt="user One"
              />
            </Button>}
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
          <List
            className="sm:flex-col flex-row gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center w-full"
            orientation="horizontal"
          >
            <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-[15px] items-center justify-start w-[99%] md:w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    style={{
                      filter: Constants.imgFilter
                    }}
                    className="h-[30px]"
                    src="images/img_user_70X70.svg"
                    alt="user One"
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Total Penalties
                  </Text>
                  <Text
                    className="text-bluegray-900 text-xl"
                    size="txtInterSemiBold20Bluegray900"
                  >
                    {penaltyDetails != null ? penaltyDetails.total : 0}
                  </Text>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-[15px] items-center justify-start w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    style={{
                      filter: Constants.imgFilter
                    }}
                    className="h-[30px]"
                    src="images/img_graph1.svg"
                    alt="graphOne"
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Total Amount
                  </Text>
                  <Text
                    className="text-bluegray-900 text-xl"
                    size="txtInterSemiBold20Bluegray900"
                  >
                    {penaltyDetails != null
                      ? `₹${penaltyDetails.totalAmount}`
                      : 0}
                  </Text>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-3 items-center justify-start my-1 w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    style={{
                      filter: Constants.imgFilter
                    }}
                    className="h-[30px]"
                    src="images/img_airplane.svg"
                    alt="airplane"
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Amount Received
                  </Text>
                  <Text
                    className="text-bluegray-900 text-lg"
                    size="txtInterSemiBold18"
                  >
                    {penaltyDetails != null
                      ? `₹${penaltyDetails.amountReceived}`
                      : 0}
                  </Text>
                </div>
              </div>
            </div>
          </List>
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <div className="flex flex-row items-center">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl] mr-5"
                size="txtInterSemiBold22"
              >
                Penalty Overview
              </Text>
              <Switch
                onColor="#359766"
                offColor="#e3e4e8"
                onHandleColor="#ffffff"
                offHandleColor="#ffffff"
                value={active}
                className="w-[15%]"
                onChange={(v) => {
                  setPage(1);
                  setActive(v);
                }}
              />
            </div>

            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">P Id</th>
                      <th scope="col" className="px-6 py-3">Customer</th>
                      <th scope="col" className="px-6 py-3">Finance ID</th>
                      <th scope="col" className="px-6 py-3">Penalty Type</th>
                      <th scope="col" className="px-6 py-3">Amount</th>
                      <th scope="col" className="px-6 py-3">Date</th>
                      <th scope="col" className="px-6 py-3">Remarks</th>
                      <th scope="col" className="px-6 py-3">Status</th>
                      <th scope="col" className="px-6 py-3">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penalties.map((e, i) =>
                      customerListItem(i != penalties.length - 1, e)
                    )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      P Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Customer
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Finance ID
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Penalty Type
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Amount
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Date
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Remarks
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Status
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Delete
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {penalties.map((e, i) =>
                  customerListItem(i != penalties.length - 1, e)
                )}
              </List> */}
            </div>
          </div>
        </div>
        {totalCount != null && totalCount > 1 && (
          <div className="flex flex-row items-center w-[30%] md:w-full">
            <div
              className="flex flex-row items-center justify-evenly w-[28%] cursor-pointer"
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Img
                style={{
                  filter: Constants.imgFilter
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Previous
              </Text>
            </div>
            <Button
              className="cursor-pointer font-medium h-10 leading-[normal] ml-3 rounded-[10px] text-[15px] text-center w-10"
              color="indigo_600"
              size="sm"
              variant="fill"
            >
              {page}
            </Button>
            <div
              className="flex flex-row items-center justify-evenly ml-3 w-[25%] cursor-pointer"
              onClick={() => {
                if (page < totalCount) {
                  setPage(page + 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Next
              </Text>
              <Img
                style={{
                  filter: Constants.imgFilter
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowright.svg"
                alt="arrowright"
              />
            </div>
          </div>
        )}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between sm:max-w-[100%] md:max-w-[100%] lg:max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Penalty
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-nowrap"
                  size="txtInterRegular16Bluegray400"
                >
                  To add a penalty provide data for all the fields.
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Customer
                  </Text>
                  <SelectBox
                    className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                    placeholderClassName="text-bluegray-900"
                    indicator={
                      <Img
                        style={{
                          filter: Constants.imgFilter
                        }}
                        className="h-6 mr-[19px] w-6"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isMulti={false}
                    options={dropdownCustomerList}
                    isSearchable={true}
                    shape="round"
                    color="white_A700"
                    size="xs"
                    variant="fill"
                    onChange={(value) => {
                      let customer: ICustomer = JSON.parse(value);
                      setSelectedCustomer(customer.id);
                      setFinance("");
                    }}
                  />
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Penalty Amount
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={amount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setAmount(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Finance
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          style={{
                            filter: Constants.imgFilter
                          }}
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                        />
                      }
                      isMulti={false}
                      options={customerFinances}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setFinance(value);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Amount in words = {`${Constants.capitalizeFirstLetter(numWords(parseInt(amount)))}`}
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-full md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Remarks
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addPenalty}
                >
                  Add Penalty
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Penalty;
