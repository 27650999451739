import React from "react";
import { IoMdClose } from "react-icons/io";
import { Button, Img, Input, Line, List, Switch, Text } from "components";
import { Constants } from "constants/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiProvider } from "apiProvider/api_provider";
import { ICustomer } from "interfaces/customer";
import { IGlobalProps } from "Routes";
import { Permission, PermissionClass } from "interfaces/user_interfaces";
import { DurationType, IMicroFinance } from "interfaces/micro_finance_interfaces";
import { IChit } from "interfaces/chit_interfaces";
import { IGeneralFinance } from "interfaces/general_finance_interfaces";
import { CloseSVG } from "assets/images";

const Customers = (props: IGlobalProps) => {
  // const customerListItem = (data: ICustomer, border: boolean) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.customerId}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.firstName}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.lastName}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.phoneNumber}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[40%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.address}
  //       </Text>
  //       <div className="md:ml-[0] ml-[20px]  text-base text-bluegray-900 w-[10%]">
  //         <img
  //           className="h-[60px] inset-[0] justify-center m-auto rounded-[50%] w-[60px]"
  //           src={
  //             data.photoId ??
  //             "https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg"
  //           }
  //           alt="EllipseTwentyEight"
  //           onClick={() =>
  //             window.open(
  //               data.photoId ??
  //               "https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg",
  //               "_blank"
  //             )
  //           }
  //         />
  //       </div>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.idNumber ?? "-"}
  //       </Text>
  //       {PermissionClass.hasPermission(Permission.CUSTOMERSWRITE) ? (
  //         <Button
  //           className="cursor-pointer font-medium leading-[normal] w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //           color="indigo_500"
  //           size="xs"
  //           variant="outline"
  //           onClick={() => {
  //             setEditFirstName(data.firstName);
  //             setEditLastName(data.lastName);
  //             setEditMobileNumber(data.phoneNumber.toString());
  //             setEditAddress(data.address);
  //             setSelectedEditId(data.id);
  //             setEditPopup(true);
  //           }}
  //         >
  //           Edit
  //         </Button>
  //       ) : (
  //         blocked()
  //       )}
  //       {
  //         <Button
  //           className="cursor-pointer font-medium leading-[normal] w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //           color="indigo_500"
  //           size="xs"
  //           variant="outline"
  //           onClick={() => {
  //             fetchFinanceDetails(data.id);
  //             setSelectedEditId(data.id);
  //             setViewPopup(true);
  //           }}
  //         >
  //           View
  //         </Button>
  //       }
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (data: ICustomer, border: boolean) => (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={data.id}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.customerId}</th>
      <td className="px-6 py-4">{data.firstName}</td>
      <td className="px-6 py-4">{data.lastName}</td>
      <td className="px-6 py-4">{data.phoneNumber}</td>
      <td className="px-6 py-4">{data.address}</td>
      <td className="px-6 py-4">
        <img
          className="h-[60px] inset-[0] justify-center m-auto rounded-[50%] w-[60px]"
          src={
            data.photoId ??
            "https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg"
          }
          alt="EllipseTwentyEight"
          onClick={() =>
            window.open(
              data.photoId ??
              "https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg",
              "_blank"
            )
          }
        />
      </td>
      <td className="px-6 py-4">{data.idNumber ?? "-"}</td>
      <td className="px-6 py-4">
        {PermissionClass.hasPermission(Permission.CUSTOMERSWRITE) ? (
          <Button
            className="cursor-pointer font-medium leading-[normal] w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              setEditFirstName(data.firstName);
              setEditLastName(data.lastName);
              setEditMobileNumber(data.phoneNumber.toString());
              setEditAddress(data.address);
              setSelectedEditId(data.id);
              setEditPopup(true);
            }}
          >
            Edit
          </Button>
        ) : (
          blocked()
        )}
      </td>
      <td className="px-6 py-4">
        {
          <Button
            className="cursor-pointer font-medium leading-[normal] w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              fetchFinanceDetails(data.id);
              setSelectedEditId(data.id);
              setViewPopup(true);
            }}
          >
            View
          </Button>
        }
      </td>
    </tr>
  );

  const [editPopup, setEditPopup] = React.useState(false);
  const [viewPopup, setViewPopup] = React.useState(false);

  const [customerList, setCustomerList] = React.useState<ICustomer[]>(null);

  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const [idNumber, setIdNumber] = React.useState<string>("");
  const [photoId, setPhotoId] = React.useState<File>();

  const [selectedEditId, setSelectedEditId] = React.useState<string>("");
  const [editFirstName, setEditFirstName] = React.useState<string>("");
  const [editLastName, setEditLastName] = React.useState<string>("");
  const [editAddress, setEditAddress] = React.useState<string>("");
  const [editMobileNumber, setEditMobileNumber] = React.useState<string>("");

  const [refresh, setRefresh] = React.useState(true);

  const [customerChits, setCustomerChits] = React.useState<IChit[]>([]);
  const [customerMicroFinances, setCustomerMicroFinances] = React.useState<
    IMicroFinance[]
  >([]);
  const [customerGeneralFinances, setCustomerGeneralFinances] = React.useState<
    IGeneralFinance[]
  >([]);

  const [page, setPage] = React.useState<number>(1);
  const [searchText, setSearchText] = React.useState<string>("");
  const [totalCount, setTotalCount] = React.useState<number>(null);

  const [updatePhotoId, setUpdatePhotoId] = React.useState<File>(null);
  const [active, setActive] = React.useState<boolean>(false);

  const addCustomer = async () => {
    if (
      firstName == "" ||
      lastName == "" ||
      mobileNumber == ""
      // address == "" ||
      // idNumber == "" ||
      // photoId == null
    ) {
      toast("Fill all fields!");
      return;
    }
    let data: ICustomer = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: parseInt(mobileNumber),
      address: address,
    };

    var formdata: any = new FormData();
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("address", address);
    formdata.append("phone_number", mobileNumber);
    formdata.append("photo_id", photoId);
    formdata.append("id_number", idNumber);

    ApiProvider.addCustomers(formdata).then((res) => {
      if (res == true) {
        clearAllFields();
        toast("Customer added successfully");
        window.location.reload();
        setRefresh(!refresh);
      } else {
        toast("Customer not added");
      }
    });
  };

  const updateCustomer = async () => {
    if (
      editFirstName == "" ||
      editLastName == "" ||
      editMobileNumber == "" ||
      editAddress == ""
    ) {
      toast("Fill all fields!");
      return;
    }
    let data: ICustomer = {
      firstName: editFirstName,
      lastName: editLastName,
      phoneNumber: parseInt(editMobileNumber),
      address: editAddress,
    };

    ApiProvider.updateCustomers(data, selectedEditId).then((res) => {
      if (res == true) {
        clearAllFields();
        toast("Customer updated successfully");
        setRefresh(!refresh);
      } else {
        toast("Customer not updated");
      }
      setEditPopup(false);
    });
  };

  const updatePhotoIdCall = async () => {
    if (updatePhotoId === null) {
      toast("Select image!");
      return;
    }

    var formdata: any = new FormData();
    formdata.append("photo_id", updatePhotoId);

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    ApiProvider.put(
      `api/update-customer-photoid/${selectedEditId}`,
      formdata, myHeaders
    ).then((res) => {
      if (res == true) {
        clearAllFields();
        toast("PhotoId added successfully");
        setRefresh(!refresh);
      } else {
        toast("PhotoId not updated");
      }
      setEditPopup(false);
    });
  };

  const getCustomers = async () => {
    ApiProvider.get(
      `api/fetch-all-customer?page=${page}&size=10&search=${searchText}`
    ).then((response) => {
      let customers: ICustomer[] = response.users.map((v, i) => {
        let c: ICustomer = {
          id: v._id,
          customerId: v.customer_id,
          firstName: v.first_name,
          lastName: v.last_name,
          phoneNumber: v.phone_number,
          address: v.address,
          photoId: v.photo_id,
          idNumber: v.id_number,
        };
        return c;
      });
      setTotalCount(Math.ceil(response.total / 10));
      setCustomerList(customers);
    });
  };

  const clearAllFields = () => {
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setAddress("");
    setPhotoId(null);
    setIdNumber("");
  };

  const fetchFinanceDetails = async (id) => {
    if (id === null) {
      return;
    }

    ApiProvider.getChitsOfCustomer(id).then((res) => {
      setCustomerChits(res);
    });

    ApiProvider.getMicroFinancesOfCustomer(id).then((res) => {
      setCustomerMicroFinances(res);
    });

    ApiProvider.getGeneralFinancesOfCustomer(id).then((res) => {
      setCustomerGeneralFinances(res);
    });
  };

  // React.useEffect(() => {
  //   fetchFinanceDetails();
  // }, [selectedEditId]);

  React.useEffect(() => {
    getCustomers();
  }, [refresh, page, searchText]);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
                style={{
                  filter: Constants.imgFilter,
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Customers
            </Text>
            <Input
              name="GroupEight"
              placeholder="Search for customers"
              value={searchText}
              onChange={(e) => setSearchText(e)}
              className="!placeholder:text-bluegray-400 !text-bluegray-400 leading-[normal] p-0 text-[15px] text-left w-[100%]"
              wrapClassName="flex md:flex-1 md:ml-[0] md:mt-0 my-[5px] rounded-[25px] w-[100%] md:w-full"
              prefix={
                <Img
                  style={{
                    filter: Constants.imgFilter,
                  }}
                  className="cursor-pointer h-5 sm:ml-[0px] md:ml-[25px] lg:ml-[25px] mr-[16px] my-[1px]"
                  src="images/img_search.svg"
                  alt="search"
                />
              }
              suffix={
                <CloseSVG
                  fillColor="#888ea2"
                  className="cursor-pointer h-5 my-auto"
                  onClick={() => setSearchText("")}
                  style={{
                    visibility: searchText?.length <= 0 ? "hidden" : "visible",
                  }}
                  height={20}
                  width={20}
                  viewBox="0 0 20 20"
                />
              }
              color="gray_101"
            ></Input>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">Id</th>
                      <th scope="col" className="px-6 py-3">First Name</th>
                      <th scope="col" className="px-6 py-3">Last Name</th>
                      <th scope="col" className="px-6 py-3">Mobile Number</th>
                      <th scope="col" className="px-6 py-3">Address</th>
                      <th scope="col" className="px-6 py-3">Photo ID</th>
                      <th scope="col" className="px-6 py-3">Number</th>
                      <th scope="col" className="px-6 py-3">Edit</th>
                      <th scope="col" className="px-6 py-3">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerList &&
                      customerList.map((e, i) =>
                        customerListItem(
                          customerList[i],
                          i != customerList.length - 1
                        )
                      )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Id
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[40%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Address
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Photo ID
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      ID Number
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Edit
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[60px]"
                      size="txtInterMedium16Bluegray400"
                    >
                      View
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {customerList &&
                  customerList.map((e, i) =>
                    customerListItem(
                      customerList[i],
                      i != customerList.length - 1
                    )
                  )}
              </List> */}
            </div>
          </div>
        </div>
        {searchText == "" && totalCount != null && totalCount > 1 && (
          <div className="flex flex-row items-center w-[30%] md:w-full">
            <div
              className="flex flex-row items-center justify-evenly w-[28%] cursor-pointer"
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Previous
              </Text>
            </div>
            <Button
              className="cursor-pointer font-medium h-10 leading-[normal] ml-3 rounded-[10px] text-[15px] text-center w-10"
              color="indigo_600"
              size="sm"
              variant="fill"
            >
              {page}
            </Button>
            <div
              className="flex flex-row items-center justify-evenly ml-3 w-[25%] cursor-pointer"
              onClick={() => {
                if (page < totalCount) {
                  setPage(page + 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Next
              </Text>
              <Img
                style={{
                  filter: Constants.imgFilter,
                }}
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowright.svg"
                alt="arrowright"
              />
            </div>
          </div>
        )}
        {PermissionClass.hasPermission(Permission.CUSTOMERSWRITE) && (
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-[98%] md:w-full">
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[66%] md:w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Add Customer
              </Text>
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full">
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Enter required information for the customer in below fields.
                  All fields are required
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={mobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setMobileNumber(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Address
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={address}
                      onChange={(e) => {
                        setAddress(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Photo ID
                    </Text>
                    <input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      // wrapClassName="border border-gray-300 border-solid w-full"
                      // shape="round"
                      color="white_A700"
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setPhotoId(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      ID Number
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={idNumber}
                      onChange={(e) => {
                        setIdNumber(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addCustomer}
                >
                  Add Customer
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          editPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => { }}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Edit Customer
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setEditPopup(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row pb-2">
                  <Text
                    className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                    size="txtInterRegular16Bluegray400"
                  >
                    {`${active == true ? "Update Other Fields" : "Update Photo ID"}`}
                  </Text>
                  <Switch
                    onColor="#359766"
                    offColor="#e3e4e8"
                    onHandleColor="#ffffff"
                    offHandleColor="#ffffff"
                    value={active}
                    className="w-[15%] mr-[20px]"
                    onChange={(v) => {
                      setActive(v);
                    }}
                  />
                </div>

                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Edit only those fields which you want to edit for the
                  customer. Please let rest of fields as it is.
                </Text>
                {active && <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Photo ID
                    </Text>
                    <input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      // wrapClassName="border border-gray-300 border-solid w-full"
                      // shape="round"
                      color="white_A700"
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setUpdatePhotoId(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>}
                {!active && <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editFirstName}
                      onChange={(e) => {
                        setEditFirstName(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editLastName}
                      onChange={(e) => {
                        setEditLastName(e);
                      }}
                    ></Input>
                  </div>
                </div>
                }
                {!active && <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editMobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setEditMobileNumber(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Address
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={editAddress}
                      onChange={(e) => {
                        setEditAddress(e);
                      }}
                    ></Input>
                  </div>
                </div>
                }
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={active ? updatePhotoIdCall : updateCustomer}
                >
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {selectedEditId != "" && (
        <section
          style={{ background: "rgba(0,0,0,0.4)" }}
          className={
            viewPopup
              ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
              : "hidden"
          }
        >
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
            <form onSubmit={(e) => { }}>
              <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[50vw]">
                <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                  <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <Text
                      className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                      size="txtInterSemiBold22"
                    >
                      Finance Id's
                    </Text>
                    <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                      <IoMdClose
                        onClick={() => {
                          setSelectedEditId("");
                          setCustomerChits([]);
                          setCustomerGeneralFinances([]);
                          setCustomerMicroFinances([]);
                          setViewPopup(false);
                        }}
                      />
                    </div>
                  </div>
                  {customerChits.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        CHIT FINANCES
                      </Text>
                      {customerChits.map((c, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {c.chit_name +
                                  " " +
                                  c.chit_id +
                                  " " +
                                  `( Total amount paid ₹${c.customers
                                    .filter((v, i) => {
                                      return v.id == selectedEditId;
                                    })[0]
                                    ?.amountPaid?.reduce(
                                      (sum, a, i, arr) => { 
                                        // if(Constants.getDateDifference(c.start_date, DurationType.MONTHS) == i) arr.splice(i)
                                        return sum + a.amount},
                                      0
                                    ) ?? 0
                                  } out of ₹${c.emis.reduce(
                                    (sum, a, i, arr) =>{
                                      if(Constants.getDateDifference(c.start_date, DurationType.MONTHS) == i) arr.splice(i)
                                      return sum +
                                      a.emiAmount *
                                      c.customers.filter((v, i) => {
                                        return v.id == selectedEditId;
                                      })[0]?.chitShare ?? 1 },
                                    0
                                  )} )`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {customerMicroFinances.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        MICRO FINANCES
                      </Text>
                      {customerMicroFinances.map((m, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {m.mf_id +
                                  " " +
                                  `( Total amount paid ₹${m.payments.reduce(
                                    (sum, a) => sum + a.amount,
                                    0
                                  )} out of ₹${m.receiving_amounts.reduce(
                                    (sum, a, i, arr) => {
                                      if(Constants.getDateDifference(m.start_date, m.duration_type) == i) arr.splice(i)
                                      return sum + a.amount},
                                    0
                                  )} )`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {customerGeneralFinances.length > 0 && (
                    <div className="flex md:flex-col flex-col gap-[30px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                      <Text
                        className="text-base text-bluegray-900"
                        size="txtInterSemiBold18"
                      >
                        GENERAL FINANCES
                      </Text>
                      {customerGeneralFinances.map((g, i) => {
                        return (
                          <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start rounded-[15px] w-[100%] md:w-full">
                            <div className="w-[100%]">
                              <Text
                                className="text-base text-bluegray-400"
                                size="txtInterRegular16Bluegray400"
                              >
                                {g.gf_id +
                                  " " +
                                  `( Interest amount paid ₹${g.payments.reduce(
                                    (sum, a) => sum + a.amount,
                                    0
                                  )} out of ₹${g.receiving_amounts.reduce(
                                    (sum, a, i, arr) => {
                                      if(Constants.getDateDifference(g.start_date, g.duration_type) == i) arr.splice(i)
                                      return sum + a.amount},
                                    0
                                  )} )`}
                              </Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Customers;

export function blocked() {
  return (
    <>
      <Text
        className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
        size="txtInterRegular16Bluegray900"
      >
        🚫
      </Text>
    </>
  );
}
