import React from "react";
import { Button, Img, Input, Line, List, SelectBox, Text } from "components";
import { IoMdClose } from "react-icons/io";
import {
  IAuction,
  IChit,
  ICustomerChit,
  IEmi,
} from "interfaces/chit_interfaces";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Constants } from "constants/constants";
import { IGlobalProps } from "Routes";
import numWords from 'num-words';

const Auctions = (props: IGlobalProps) => {
  const [refresh, setRefresh] = React.useState(true);
  const [editPopup, setEditPopup] = React.useState(false);
  const [addPopup, setAddPopup] = React.useState(false);
  const [chitsList, setChitsList] = React.useState<IChit[]>([]);
  const [editModeOn, setEditModeOn] = React.useState(false);

  const [auctionAmount, setAuctionAmount] = React.useState<string>("");
  const [distributionAmount, setDistributionAmount] =
    React.useState<string>("");
  const [emiPerCustomer, setEmiPerCustomer] = React.useState<string>("");
  const [distributionDate, setDistributionDate] = React.useState<string>("");
  const [auctionDate, setAuctionDate] = React.useState<string>("");

  const [selectedChit, setSelectedChit] = React.useState<IChit>(null);
  const [selectedAuctionIndex, setSelectedAuctionIndex] =
    React.useState<number>(null);

  const [auctionWinner, setAuctionWinner] = React.useState<ICustomerChit>(null);

  const addAuctionWinner = async () => {
    if (auctionWinner == null) {
      toast("Select auction winner from dropdown");
      return;
    }

    let data: IAuction[] = selectedChit.auctions;
    data[selectedAuctionIndex].auctionWinner = `${auctionWinner.firstName + " " + auctionWinner.lastName}`;

    ApiProvider.addAuctionWinner(data, selectedChit.id).then((res) => {
      setEditPopup(false);
      clearFields();
      if (res == true) {
        setAddPopup(false);
        toast("Winner added successfully");
        setRefresh(!refresh);
      } else {
        toast("Winner not added");
      }
    });
  };

  const addAuction = async () => {
    if (
      auctionAmount === "" ||
      distributionAmount === "" ||
      emiPerCustomer === "" ||
      distributionDate === "" ||
      auctionDate === ""
    ) {
      toast("Fill all fields");
      return;
    }

    let auctionData: IAuction[] = selectedChit.auctions;
    auctionData[selectedAuctionIndex].auctionAmount = parseInt(auctionAmount);
    auctionData[selectedAuctionIndex].distributionAmount =
      parseInt(distributionAmount);
    auctionData[selectedAuctionIndex].auctionDate = auctionDate;
    auctionData[selectedAuctionIndex].distributionDate = distributionDate;

    let emiData: IEmi[] = selectedChit.emis;

    emiData[selectedAuctionIndex].emiAmount = parseInt(emiPerCustomer);
    emiData[selectedAuctionIndex].emiDate = distributionDate;

    ApiProvider.addAuction(auctionData, emiData, selectedChit.id).then(
      (res) => {
        clearFields();
        if (res == true) {
          setAddPopup(false);
          toast("Auction added successfully");
          setRefresh(!refresh);
        } else {
          toast("Auction not added");
        }
      }
    );
  };

  const fetchChits = () => {
    ApiProvider.getAllChits(true).then((res) => {
      setChitsList(res);
    });
  };

  React.useEffect(() => {
    fetchChits();
  }, [refresh]);

  const clearFields = () => {
    setSelectedAuctionIndex(null);
    setAuctionWinner(null);
    setAuctionAmount("");
    setDistributionAmount("");
    setEmiPerCustomer("");
    setDistributionDate("");
    setAuctionDate("");
  };

  // const auctionListItem = (border: boolean, index: number) => (
  //   <div className="flex sm:flex-row md:flex-row flex-col gap-3.5 items-center justify-start w-full" key={index}>
  //     <div className="flex sm:flex-row md:flex-row lg:flex-row flex-row md:gap-5 items-center justify-start rounded-[17.5px] w-[100%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.auctions[index].auction_id}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`${
  //           selectedChit.auctions[index].auctionAmount != null
  //             ? "₹" + selectedChit.auctions[index].auctionAmount
  //             : "-"
  //         }`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`${
  //           selectedChit.auctions[index].distributionAmount != null
  //             ? "₹" + selectedChit.auctions[index].distributionAmount
  //             : "-"
  //         }`}
  //       </Text>
  //       <Text
  //         className="ml-[20px] md:ml-[0] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.auctions[index].distributionDate != null ? Constants.formatDate(selectedChit.auctions[index].distributionDate) : "-"}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.auctions[index].auctionDate ? Constants.formatDate(selectedChit.auctions[index].auctionDate) : "-"}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[20%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {selectedChit.auctions[index].auctionWinner ?? "-"}
  //       </Text>
  //       {!selectedChit.auctions[index].auctionWinner ? (
  //         <Button
  //           className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"
  //           color="indigo_500"
  //           size="xs"
  //           variant="outline"
  //           onClick={() => {
  //             setSelectedAuctionIndex(index);
  //             if (selectedChit.auctions[index].auctionAmount == null) {
  //               setAddPopup(true);
  //             } else {
  //               setEditPopup(true);
  //             }
  //           }}
  //         >
  //           {selectedChit.auctions[index].auctionAmount == null
  //             ? "Add"
  //             : "Add Winner"}
  //         </Button>
  //       ) : (
  //         <div className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"></div>
  //       )}
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const auctionListItem = (border: boolean, index: number) => (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={index}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{selectedChit.auctions[index].auction_id}</th>
      <td className="px-6 py-4">{`${selectedChit.auctions[index].auctionAmount != null
        ? "₹" + selectedChit.auctions[index].auctionAmount
        : "-"
        }`}</td>
      <td className="px-6 py-4">{`${selectedChit.auctions[index].distributionAmount != null
        ? "₹" + selectedChit.auctions[index].distributionAmount
        : "-"
        }`}</td>
      <td className="px-6 py-4">{selectedChit.auctions[index].distributionDate != null ? Constants.formatDate(selectedChit.auctions[index].distributionDate) : "-"}</td>
      <td className="px-6 py-4">{selectedChit.auctions[index].auctionDate ? Constants.formatDate(selectedChit.auctions[index].auctionDate) : "-"}</td>
      <td className="px-6 py-4"> {selectedChit.auctions[index].auctionWinner ?? "-"}</td>
      <td className="px-6 py-4">
        {<Button
            className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              setSelectedAuctionIndex(index);
              setAddPopup(true);
            }}
          >
            {selectedChit.auctions[index].auctionAmount == null
              ? "Add"
              : "Edit"}
          </Button>}
      </td>
      <td className="px-6 py-4">
        {selectedChit.auctions[index].auctionAmount != null && !selectedChit.auctions[index].auctionWinner ? (
          <Button
            className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              setEditModeOn(false);
              setSelectedAuctionIndex(index);
              setEditPopup(true);
            }}
          >
            Add Winner
          </Button>
        ) : 
        selectedChit.auctions[index].auctionWinner != null ? (
          <Button
            className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"
            color="indigo_500"
            size="xs"
            variant="outline"
            onClick={() => {
              setEditModeOn(true);
              setSelectedAuctionIndex(index);
              setEditPopup(true);
            }}
          >
            Edit Winner
          </Button>
        ) :
        (
          <div className="cursor-pointer font-medium leading-[normal] min-w-[60px] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center w-[10%]"></div>
        )}
      </td>
    </tr>
  );

  const getAuctionEmi = (amount: number) => {
    let auctionPrice: number = amount;
    let chitComission: number =
      (selectedChit.chit_amount * selectedChit.commission) / 100;
    let totalChits = 0;
    selectedChit.customers.forEach((v, i) => {
      totalChits += parseInt(v.chitShare.toString());
    });
    let grossEmi: number = parseFloat(
      (selectedChit.chit_amount / totalChits).toFixed(2)
    );
    let auctionEmi: number = (auctionPrice - chitComission) / totalChits;
    return parseFloat((grossEmi - auctionEmi).toFixed(2));
  };

  const setDistributionAmountAndEmiPerCustomer = (rate: string) => {
    if (rate !== "") {
      let amount: number = parseInt(rate);
      setDistributionAmount((selectedChit.chit_amount - amount).toString());
      setEmiPerCustomer(getAuctionEmi(amount).toString());
    } else {
      setDistributionAmount("");
      setEmiPerCustomer("");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex sm:flex-row md:flex-row lg:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
                style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Auctions
            </Text>
          </div>
          <div className="flex flex-row">
            <SelectBox
              className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-[160px]"
              placeholderClassName="text-bluegray-900"
              indicator={
                <Img
                  className="h-6 mr-[19px] w-6"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                  style={{
                    filter: Constants.imgFilter
                  }}
                />
              }
              isMulti={false}
              options={chitsList.map((v, i) => {
                return { label: v.chit_name, value: JSON.stringify(v) };
              })}
              isSearchable={false}
              shape="round"
              color="white_A700"
              size="xs"
              variant="fill"
              onChange={(value) => {
                let data: IChit = JSON.parse(value);
                setSelectedChit(data);
              }}
            />
          </div>
        </div>
        {selectedChit != null ? (
          <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
            <List
              className="sm:flex-col flex-row gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-center w-full"
              orientation="horizontal"
            >
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start w-[99%] md:w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_user_70X70.svg"
                      alt="user One"
                      style={{
                        filter: Constants.imgFilter
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Id
                    </Text>
                    <Text
                      className="text-bluegray-900 text-xl"
                      size="txtInterSemiBold20Bluegray900"
                    >
                      {selectedChit.chit_id}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start my-1 w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_reply.svg"
                      alt="bag"
                      style={{
                        filter: Constants.imgFilter
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-bluegray-900"
                      size="txtInterRegular16Bluegray400"
                    >
                      {selectedChit.start_date && Constants.formatDate(selectedChit.start_date)}
                    </Text>
                    <Text
                      className="text-bluegray-900"
                      size="txtInterRegular16Bluegray400"
                    >
                      {selectedChit.end_date && Constants.formatDate(selectedChit.end_date)}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-[15px] items-center justify-start w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_graph1.svg"
                      alt="graphOne"
                      style={{
                        filter: Constants.imgFilter
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Chit Value
                    </Text>
                    <Text
                      className="text-bluegray-900 text-xl"
                      size="txtInterSemiBold20Bluegray900"
                    >
                      {`₹${selectedChit.chit_amount}`}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start sm:ml-[0] p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="flex flex-row gap-3 items-center justify-start my-1 w-full">
                  <Button
                    className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                    shape="circle"
                    color="gray_102"
                    size="lg"
                    variant="fill"
                  >
                    <Img
                      className="h-[30px]"
                      src="images/img_airplane.svg"
                      alt="airplane"
                      style={{
                        filter: Constants.imgFilter
                      }}
                    />
                  </Button>
                  <div className="flex flex-col gap-2 items-start justify-start">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Commission
                    </Text>
                    <Text
                      className="text-bluegray-900 text-lg"
                      size="txtInterSemiBold18"
                    >
                      {`${selectedChit.commission}%`}
                    </Text>
                  </div>
                </div>
              </div>
            </List>
            <div className="flex flex-col gap-[15px] items-start justify-start w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Auctions Overview
              </Text>
              <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">Auction Id</th>
                        <th scope="col" className="px-6 py-3">Auction Amount</th>
                        <th scope="col" className="px-6 py-3">Distribution Amount</th>
                        <th scope="col" className="px-6 py-3">Distribution Date</th>
                        <th scope="col" className="px-6 py-3">Auction Date</th>
                        <th scope="col" className="px-6 py-3">Auction Winner</th>
                        <th scope="col" className="px-6 py-3">Add/Edit</th>
                        <th scope="col" className="px-6 py-3">Add Winner</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedChit &&
                        selectedChit.auctions.map((e, i) =>
                          auctionListItem(i != selectedChit.auctions.length - 1, i)
                        )}
                    </tbody>
                  </table>
                </div>
                {/* <List
                  className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                  orientation="vertical"
                >
                  <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                    <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400 w-[15%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Auction Id
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Auction Amount
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Distribution Amount
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Distribution Date
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Auction Date
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Auction Winner
                      </Text>
                      <Text
                        className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                        size="txtInterMedium16Bluegray400"
                      >
                        Add/Edit
                      </Text>
                    </div>
                    <Line className="bg-gray-103 h-px w-full" />
                  </div>
                  {selectedChit &&
                    selectedChit.auctions.map((e, i) =>
                      auctionListItem(i != selectedChit.auctions.length - 1, i)
                    )}
                </List> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6 items-center justify-center w-[98%] md:w-full h-[70vh]">
            <div className="bg-white-A700 flex flex-col items-center justify-end p-[27px] sm:px-5 rounded-[25px] w-[50%] sm__w_mobile_100">
              <div className="flex flex-col gap-[27px] items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start rounded-[10px] w-full">
                  <div className="flex flex-col gap-3 items-center justify-start w-full">
                    <div className="flex sm:flex-row flex-row items-end justify-between w-full">
                      <div className="bg-indigo-600 h-[135px] sm:mt-0 mt-[99px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[234px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[106px] sm:mt-0 mt-32 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[186px] sm:mt-0 mt-12 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[102px] sm:mt-0 mt-[132px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[139px] sm:mt-0 mt-[95px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[212px] sm:mt-0 mt-[22px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[123px] sm:mt-0 mt-[111px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[150px] sm:mt-0 mt-[84px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[214px] sm:mt-0 mt-5 rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[158px] sm:mt-0 mt-[76px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[105px] sm:mt-0 mt-[129px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-600 h-[179px] sm:mt-0 mt-[55px] rounded-[10px] w-[5%]"></div>
                      <div className="bg-indigo-200 h-[216px] sm:mt-0 mt-[18px] rounded-[10px] w-[5%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-center w-[93%] md:w-full">
                      <Text
                        className="text-bluegray-600 text-l text-center"
                        size="txtInterRegular14"
                      >
                        To view auction details select chit from the dropdown
                        above
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedChit && (
        <section
          style={{ background: "rgba(0,0,0,0.4)" }}
          className={
            addPopup
              ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
              : "hidden"
          }
        >
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between sm__w_mx_mobile_100 max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
            <form onSubmit={(e) => { }}>
              <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
                <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                  <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <Text
                      className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                      size="txtInterSemiBold22"
                    >
                      Add Auction
                    </Text>
                    <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                      <IoMdClose
                        onClick={() => {
                          setAddPopup(false);
                          clearFields();
                        }}
                      />
                    </div>
                  </div>
                  <Text
                    className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal"
                    size="txtInterRegular16Bluegray400"
                  ></Text>
                  <div className="flex md:flex-col flex-col gap-[11px] items-start justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Auction Amount
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={auctionAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setAuctionAmount(e);
                          setDistributionAmountAndEmiPerCustomer(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Amount in words = {`${Constants.capitalizeFirstLetter(numWords(parseInt(auctionAmount)))}`}
                </Text>
                  <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        Distribution Amount
                      </Text>
                      <Input
                        name="Group195"
                        className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                        wrapClassName="border border-gray-300 border-solid w-full"
                        shape="round"
                        color="white_A700"
                        value={distributionAmount}
                        disabled
                      ></Input>
                    </div>
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        EMI Per Chit
                      </Text>
                      <Input
                        name="Group195 One"
                        className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                        wrapClassName="border border-gray-300 border-solid w-full"
                        shape="round"
                        color="white_A700"
                        disabled
                        value={emiPerCustomer}
                      ></Input>
                    </div>
                  </div>
                  <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        Distribution Date
                      </Text>
                      <Input
                        name="Group195 Two"
                        className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                        wrapClassName="border border-gray-300 border-solid w-full"
                        shape="round"
                        color="white_A700"
                        value={distributionDate}
                        type="date"
                        onChange={(e) => {
                          setDistributionDate(e);
                        }}
                      ></Input>
                    </div>
                    <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                      <Text
                        className="text-base text-bluegray-400"
                        size="txtInterRegular16Bluegray400"
                      >
                        Auction Date
                      </Text>
                      <Input
                        name="Group195"
                        className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                        wrapClassName="border border-gray-300 border-solid w-full"
                        shape="round"
                        color="white_A700"
                        type="date"
                        value={auctionDate}
                        onChange={(e) => {
                          setAuctionDate(e);
                        }}
                      ></Input>
                    </div>
                  </div>
                  <Button
                    className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                    color="indigo_600"
                    size="md"
                    variant="fill"
                    type="button"
                    onClick={addAuction}
                  >
                    Add Auction
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
      {selectedChit && (
        <section
          style={{ background: "rgba(0,0,0,0.4)" }}
          className={
            editPopup
              ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
              : "hidden"
          }
        >
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between sm__w_mx_mobile_100 max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
            <form onSubmit={(e) => { }}>
              <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
                <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-[40vw] overflow-auto">
                  <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <Text
                      className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                      size="txtInterSemiBold22"
                    >
                      {editModeOn ? "Edit Winner" : "Add Winner"}
                    </Text>
                    <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                      <IoMdClose
                        onClick={() => {
                          setEditPopup(false);
                          clearFields();
                        }}
                      />
                    </div>
                  </div>
                  <Text
                    className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                    size="txtInterRegular16Bluegray400"
                  >
                    Select winner from the below dropdown
                  </Text>
                  <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-[100%]">
                    <Text
                      className="text-base text-bluegray-400 whitespace-nowrap"
                      size="txtInterRegular16Bluegray400"
                    >
                      Auction Winner
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: Constants.imgFilter
                          }}
                        />
                      }
                      isMulti={false}
                      options={selectedChit.customers.map((v, i) => {
                        return {
                          label: v.firstName + " " + v.lastName,
                          value: JSON.stringify(v),
                        };
                      })}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setAuctionWinner(JSON.parse(value));
                      }}
                    />
                  </div>
                  <Button
                    className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                    color="indigo_600"
                    size="md"
                    variant="fill"
                    type="button"
                    onClick={addAuctionWinner}
                  >
                    Add Winner
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Auctions;
