import React from "react";
import { Sidebar } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import { Img, Line, Text } from "components";
import { useNavigate } from "react-router-dom";
import { Constants, SideBarType } from "constants/constants";
import { Permission, PermissionClass } from "interfaces/user_interfaces";

type GlobalSidebarProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setIsSideBarOpen: (v: boolean) => void;
  }>;

const GlobalSidebar: React.FC<GlobalSidebarProps> = (props) => {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState<SideBarType>(
    SideBarType.Dashboard
  );

  const selectedType = (title: string, image: string, type: SideBarType) => (
    <div
      className="flex flex-row gap-[20px] items-center justify-start w-[72%] md:w-full cursor-pointer"
      onClick={() => setOnClick(type)}
    >
      <Line className="bg-indigo-600 h-[60px] rounded-br-[10px] rounded-tr-[10px] w-1.5" />
      <div className="flex flex-row gap-5 items-center justify-start w-[78%]">
        <Img
          className="h-[25px] w-[25px]"
          src={image}
          alt="home"
          style={{
            filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
          }}
        />
        <Text
          className="text-indigo-600 text-lg whitespace-nowrap"
          size="txtInterMedium18"
        >
          {title}
        </Text>
      </div>
    </div>
  );

  const unSelectedType = (title: string, image: string, type: SideBarType) => (
    <div
      className="common-pointer flex flex-col items-start justify-start py-[17px] w-full"
      onClick={() => setOnClick(type)}
    >
      <div className="flex flex-row gap-5 items-center justify-start md:ml-[0] ml-[23px] w-[73%] md:w-full">
        <Img className="h-[25px] w-[25px] " src={image} alt="Glyph" />
        <Text
          className="text-bluegray-400 text-lg whitespace-nowrap"
          size="txtInterMedium18Bluegray400"
        >
          {title}
        </Text>
      </div>
    </div>
  );

  const setOnClick = (sideBarType: SideBarType) => {
    switch (sideBarType) {
      case SideBarType.Dashboard: {
        navigate("/dashboard");
        setSelected(SideBarType.Dashboard);
        break;
      }
      case SideBarType.ChitFinance: {
        navigate("/chit_finance");
        setSelected(SideBarType.ChitFinance);
        break;
      }
      case SideBarType.Auctions: {
        navigate("/auctions");
        setSelected(SideBarType.Auctions);
        break;
      }
      case SideBarType.MicroFinance: {
        navigate("/micro_finance");
        setSelected(SideBarType.MicroFinance);
        break;
      }
      case SideBarType.GeneralFinance: {
        navigate("/general_finance");
        setSelected(SideBarType.GeneralFinance);
        break;
      }
      case SideBarType.Customers: {
        navigate("/customers");
        setSelected(SideBarType.Customers);
        break;
      }
      case SideBarType.Payments: {
        navigate("/payments");
        setSelected(SideBarType.Payments);
        break;
      }
      case SideBarType.Penalty: {
        navigate("/penalty");
        setSelected(SideBarType.Penalty);
        break;
      }
      case SideBarType.Borrowings: {
        navigate("/borrowings");
        setSelected(SideBarType.Borrowings);
        break;
      }
      case SideBarType.Users: {
        navigate("/users");
        setSelected(SideBarType.Users);
        break;
      }
      case SideBarType.Support: {
        navigate("/support");
        setSelected(SideBarType.Support);
        break;
      }
    }
  };

  const location = useLocation();

  React.useEffect(() => {
    switch (location.pathname) {
      case "/dashboard": {
        setSelected(SideBarType.Dashboard);
        break;
      }
      case "/chit_finance": {
        setSelected(SideBarType.ChitFinance);
        break;
      }
      case "/auctions": {
        setSelected(SideBarType.Auctions);
        break;
      }
      case "/micro_finance": {
        setSelected(SideBarType.MicroFinance);
        break;
      }
      case "/general_finance": {
        setSelected(SideBarType.GeneralFinance);
        break;
      }
      case "/customers": {
        setSelected(SideBarType.Customers);
        break;
      }
      case "/payments": {
        setSelected(SideBarType.Payments);
        break;
      }
      case "/penalty": {
        setSelected(SideBarType.Penalty);
        break;
      }
      case "/borrowings": {
        setSelected(SideBarType.Borrowings);
        break;
      }
      case "/users": {
        setSelected(SideBarType.Users);
        break;
      }
      case "/support": {
        setSelected(SideBarType.Support);
        break;
      }
      default: {
        setSelected(SideBarType.Dashboard);
        break;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Sidebar className="sidebar__mobile__toggle bg-white-A700 !sticky !w-[240px] flex h-[100%] md:hidden justify-start overflow-auto md:px-5 top-[0]">
        <div className="flex flex-col gap-[50px] items-start justify-start mb-[364px] mt-[15px] w-full sidebar__mobile__toggle__mb_0">
          <div className="flex flex-row justify-start  items-center w-[120px]">
            <div className="flex flex-row gap-[20px] items-center justify-start w-[120px] md:w-full">
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}
                onClick={() => {
                  props.setIsSideBarOpen(false);
                }}
              />
              <img
                src={`${Constants.imageBaseUrl}images/logo(2).png`}
                alt="Logo"
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full">
            {selected === SideBarType.Dashboard
              ? selectedType(
                  "Dashboard",
                  "images/img_home.svg",
                  SideBarType.Dashboard
                )
              : unSelectedType(
                  "Dashboard",
                  "images/img_home_25X25.svg",
                  SideBarType.Dashboard
                )}
            {PermissionClass.hasPermission(Permission.CHITREAD) &&
              (selected === SideBarType.ChitFinance
                ? selectedType(
                    "Chit Finance",
                    "images/img_question_60X60.svg",
                    SideBarType.ChitFinance
                  )
                : unSelectedType(
                    "Chit Finance",
                    "images/img_question.svg",
                    SideBarType.ChitFinance
                  ))}
            {PermissionClass.hasPermission(Permission.ADMIN) &&
              (selected === SideBarType.Auctions
                ? selectedType(
                    "Auctions",
                    "images/img_question_60X60.svg",
                    SideBarType.Auctions
                  )
                : unSelectedType(
                    "Auctions",
                    "images/img_question.svg",
                    SideBarType.Auctions
                  ))}
            {PermissionClass.hasPermission(Permission.MFREAD) &&
              (selected === SideBarType.MicroFinance
                ? selectedType(
                    "Micro Finance",
                    "images/img_question_60X60.svg",
                    SideBarType.MicroFinance
                  )
                : unSelectedType(
                    "Micro Finance",
                    "images/img_question.svg",
                    SideBarType.MicroFinance
                  ))}
            {PermissionClass.hasPermission(Permission.GFREAD) &&
              (selected === SideBarType.GeneralFinance
                ? selectedType(
                    "General Finance",
                    "images/img_question_60X60.svg",
                    SideBarType.GeneralFinance
                  )
                : unSelectedType(
                    "General Finance",
                    "images/img_question.svg",
                    SideBarType.GeneralFinance
                  ))}
            {PermissionClass.hasPermission(Permission.CUSTOMERSREAD) &&
              (selected === SideBarType.Customers
                ? selectedType(
                    "Customers",
                    "images/img_user_70X70.svg",
                    SideBarType.Customers
                  )
                : unSelectedType(
                    "Customer",
                    "images/img_user.svg",
                    SideBarType.Customers
                  ))}
            {PermissionClass.hasPermission(Permission.PAYMENTREAD) &&
              (selected === SideBarType.Payments
                ? selectedType(
                    "Payment",
                    "images/img_map_filled.svg",
                    SideBarType.Payments
                  )
                : unSelectedType(
                    "Payment",
                    "images/img_map.svg",
                    SideBarType.Payments
                  ))}
            {PermissionClass.hasPermission(Permission.PENALTYREAD) &&
              (selected === SideBarType.Penalty
                ? selectedType(
                    "Penalty",
                    "images/img_file_1.svg",
                    SideBarType.Penalty
                  )
                : unSelectedType(
                    "Penalty",
                    "images/img_file.svg",
                    SideBarType.Penalty
                  ))}
            {PermissionClass.hasPermission(Permission.ADMIN) &&
              (selected === SideBarType.Borrowings
                ? selectedType(
                    "Borrowings",
                    "images/img_question_60X60.svg",
                    SideBarType.Borrowings
                  )
                : unSelectedType(
                    "Borrowings",
                    "images/img_question.svg",
                    SideBarType.Borrowings
                  ))}
            {PermissionClass.hasPermission(Permission.ADMIN) &&
              (selected === SideBarType.Users
                ? selectedType(
                    "Users",
                    "images/img_user_70X70.svg",
                    SideBarType.Users
                  )
                : unSelectedType(
                    "Users",
                    "images/img_user.svg",
                    SideBarType.Users
                  ))}
            {selected === SideBarType.Support
              ? selectedType(
                  "Support",
                  "images/img_service1_filled.svg",
                  SideBarType.Support
                )
              : unSelectedType(
                  "Support",
                  "images/img_service1.svg",
                  SideBarType.Support
                )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

GlobalSidebar.defaultProps = {};

export default GlobalSidebar;
