import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Input, Line, Switch, Text } from "components";
import { ToastContainer, toast } from "react-toastify";
import { ApiProvider } from "apiProvider/api_provider";
import { IGlobalProps } from "Routes";
import { Constants } from "constants/constants";

const Support  = (props: IGlobalProps) => {
  const [currentPassword, setCurrentPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");

  const changePassword = async () => {
    if (currentPassword == "" || newPassword == "") {
      toast("Enter both fields");
      return;
    }

    let raw = {
      email: localStorage.getItem("vsmemail"),
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    const url = ApiProvider.baseUrl + "api/change-password";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("vsm")}`);

    var requestOptions: RequestInit = {
      method: "PUT",
      body: JSON.stringify(raw),
      headers: myHeaders,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                toast(res.message);
              } else {
                setCurrentPassword("");
                setNewPassword("");
                toast("Password changed successfully")
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="flex md:flex-1 flex-col gap-[31px] items-center justify-start md:px-5 w-[100%] md:w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-start p-5 w-full h-[80px]">
        {props.isSideBarOpen == false && (
          <Img
            className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
            src="images/img_arrowleft.svg"
            alt="Logo"
            onClick={() => {
              props.setIsSideBarOpen(true);
            }}
            style={{
              filter: Constants.imgFilter
            }}
          />
        )}
          <Text
            className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900"
            size="txtInterSemiBold28"
          >
            Support
          </Text>
        </div>
        <div className="bg-white-A700 flex flex-col items-start justify-end p-[30px] sm:px-5 rounded-[25px] w-[99%] md:w-full">
          <div className="flex flex-col justify-start mt-[7px] w-full">
            <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start md:ml-[0] w-2/5 md:w-full">
              <div className="flex flex-col gap-2 items-center justify-start sm:ml-[0] w-[28%] sm:w-full">
                <Text
                  className="text-base text-indigo-600"
                  size="txtInterMedium16Indigo600"
                >
                  Security
                </Text>
                <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
              </div>
            </div>
            <Line className="bg-gray-300 h-px w-full" />
          </div>
          <div className="flex flex-col items-start justify-start mt-[27px] rounded-[15px] w-[49%] md:w-full">
            <Text
              className="text-[17px] text-bluegray-900"
              size="txtInterMedium17"
            >
              Change Password
            </Text>
            <div className="flex flex-col gap-[11px] items-start justify-start mt-4 rounded-[15px] w-full">
              <Text
                className="text-base text-bluegray-400"
                size="txtInterRegular16Bluegray400"
              >
                Current Password
              </Text>
              <Input
                name="Group195"
                className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                wrapClassName="border border-gray-300 border-solid w-full"
                shape="round"
                color="white_A700"
                value={currentPassword}
                onChange={(v) => {
                  setCurrentPassword(v);
                }}
              ></Input>
            </div>
            <div className="flex flex-col gap-[11px] items-start justify-start mt-[22px] rounded-[15px] w-full">
              <Text
                className="text-base text-bluegray-400"
                size="txtInterRegular16Bluegray400"
              >
                New Password
              </Text>
              <Input
                name="Group195 One"
                className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                wrapClassName="border border-gray-300 border-solid w-full"
                shape="round"
                color="white_A700"
                value={newPassword}
                onChange={(v) => {
                  setNewPassword(v);
                }}
              ></Input>
            </div>
          </div>
          <Button
            className="cursor-pointer font-medium leading-[normal] min-w-[190px] md:ml-[0] ml-[860px] mt-[30px] rounded-[15px] text-center text-lg"
            color="indigo_600"
            size="md"
            variant="fill"
            type="button"
            onClick={changePassword}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default Support;
