import React from "react";

import { Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

import { Button, Img, Input, Line, List, SelectBox, Text } from "components";

import { CloseSVG } from "../../assets/images";
import {
  IDashboardFinanceAmount,
  IDashboardFinanceTitle,
} from "interfaces/dashboard_finance_insight";
import { Constants } from "constants/constants";
import { IGlobalProps } from "Routes";
import { IPayment } from "interfaces/payment_interfaces";
import { ApiProvider } from "apiProvider/api_provider";
import { IChit } from "interfaces/chit_interfaces";
import {
  FinanceStatus,
  IMicroFinance,
} from "interfaces/micro_finance_interfaces";
import { IGeneralFinance } from "interfaces/general_finance_interfaces";

const MainDashboardPage = (props: IGlobalProps) => {
  const [payments, setPayments] = React.useState<IPayment[]>([]);
  const [chitsList, setChitsList] = React.useState<IChit[]>([]);
  const [microfinanceList, setMicrofinanceList] = React.useState<
    IMicroFinance[]
  >([]);
  const [generalfinanceList, setGeneralfinanceList] = React.useState<
    IGeneralFinance[]
  >([]);

  const detailInsightsCard = (
    titles: IDashboardFinanceTitle,
    amounts: IDashboardFinanceAmount
  ) => (
    <div className="bg-white-A700 flex flex-1 sm:flex-row flex-row sm:gap-5 items-center justify-start p-[15px] rounded-[20px] w-full">
      <Button
        className="flex h-[60px] items-center justify-center w-[60px]"
        shape="round"
        color="gray_102"
        size="md"
        variant="fill"
      >
        <Img src="images/img_minimize.svg" alt="eye" style={{
                  filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                }}/>
      </Button>
      <div className="flex flex-col gap-[7px] items-start justify-start ml-5 sm:ml-[0] w-[50%]">
        <Text
          className="text-base text-bluegray-900"
          size="txtInterMedium16Bluegray900"
        >
          ₹{amounts.borrowedAmount}
        </Text>
        <Text
          className="text-[15px] text-bluegray-400"
          size="txtInterRegular15Bluegray400"
        >
          {titles.borrowedTitle}
        </Text>
      </div>
      <div className="flex flex-col gap-[7px] items-start justify-start ml-14 sm:ml-[0] w-[50%]">
        <Text
          className="text-base text-bluegray-900"
          size="txtInterMedium16Bluegray900"
        >
          ₹{amounts.loanPaidAmount}
        </Text>
        <Text
          className="text-[15px] text-bluegray-400"
          size="txtInterRegular15Bluegray400"
        >
          {titles.loanPaidTitle}
        </Text>
      </div>
      {/* <div className="flex flex-col gap-[7px] items-start justify-start ml-11 sm:ml-[0]">
        <Text
          className="text-base text-green-600"
          size="txtInterMedium16Green600"
        >
          ₹{amounts.balanceAmount}
        </Text>
        <Text
          className="text-[15px] text-bluegray-400"
          size="txtInterRegular15Bluegray400"
        >
          {titles.balanceTitle}
        </Text>
      </div> */}
    </div>
  );

  const recentTransactionCard = (data: IPayment) => (
    <div className="flex flex-1 flex-row items-center justify-start w-full">
      <Button
        className="flex h-[60px] items-center justify-center w-[60px]"
        shape="round"
        color="gray_102"
        size="md"
        variant="fill"
      >
        <Img
          className="h-[25px]"
          src="images/img_group326.svg"
          alt="Group936"
          style={{
            filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
          }}
        />
      </Button>
      <div className="flex flex-col gap-[7px] items-start justify-start ml-5 w-[40%]">
        <Text
          className="text-base text-bluegray-900"
          size="txtInterMedium16Bluegray900"
        >
          {data.customer.firstName + " " + data.customer.lastName}
        </Text>
        <Text
          className="text-[15px] text-bluegray-400"
          size="txtInterRegular15Bluegray400"
        >
          {Constants.formatDateByTimeStamps(data.createdAt)}
        </Text>
      </div>
      <Text
        className="ml-[102px] text-base text-indigo-600"
        size="txtInterBold16Indigo600"
      >
        {`₹${data.totalAmount}`}
      </Text>
    </div>
  );

  const getAllPayments = async () => {
    ApiProvider.getPayments(`api/get-payment?page=1&size=3`).then((res) => {
      setPayments(res.payments);
    });
  };

  const fetchChits = () => {
    ApiProvider.getAllChits(true).then((res) => {
      setChitsList(res);
    });
  };

  const fetchMicrofinances = () => {
    ApiProvider.getMicroFinances(
      `api/get-microfinance?status=${FinanceStatus.ONGOING}&search=`
    ).then((res) => {
      setMicrofinanceList(res.mf);
    });
  };

  const fetchGeneralfinances = () => {
    ApiProvider.getGeneralFinances(
      `api/get-generalfinance?status=${FinanceStatus.ONGOING}&search=`
    ).then((res) => {
      setGeneralfinanceList(res.gf);
    });
  };

  React.useEffect(() => {
    getAllPayments();
    fetchChits();
    fetchMicrofinances();
    fetchGeneralfinances();
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 w-full">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex sm:flex-row md:flex-row lg:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full h-[80px]">
          {props.isSideBarOpen == false && (
            <Img
              className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
              src="images/img_arrowleft.svg"
              alt="Logo"
              style={{
                filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
              }}
              onClick={() => {
                props.setIsSideBarOpen(true);
              }}
            />
          )}
          <Text
            className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900"
            size="txtInterSemiBold28"
          >
            Dashboard
          </Text>
          <div className="flex flex-row">
            {/* <Button
              className="flex h-[50px] items-center justify-center md:ml-[0] ml-[30px] md:mt-0 my-[5px] w-[50px]"
              shape="circle"
              color="gray_102"
              size="sm"
              variant="fill"
              type="button"
              
            > */}
              <img
                className="h-[50px] cursor-pointer"
                src="images/logout.png"
                alt="settings One"
                onClick={() => {
                  localStorage.clear();
                  document.location.href = "/";
                }}
              />
            {/* </Button> */}
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full">
          <List
            className="sm:flex-col flex-row gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center w-full"
            orientation="horizontal"
          >
            <div className="bg-white-A700 flex flex-1 flex-col items-start justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-[15px] items-center justify-start w-[85%] md:w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    className="h-[31px]"
                    src="images/img_contrast_70X70.svg"
                    alt="contrast"
                    style={{
                      filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                    }}
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Chits Total Amount ({chitsList.length})
                  </Text>
                  <Text
                    className="text-indigo-600 text-xl"
                    size="txtInterSemiBold20"
                  >
                    {`₹${chitsList.reduce((sum, c) => sum + c.chit_amount, 0)}`}
                  </Text>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col items-start justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-[15px] items-center justify-start w-[88%] md:w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    className="h-[30px]"
                    src="images/img_minimize.svg"
                    alt="minimize"
                    style={{
                      filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                    }}
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    Micro Finance Insights ({microfinanceList.length})
                  </Text>
                  <Text
                    className="text-indigo-600 text-xl"
                    size="txtInterSemiBold20"
                  >
                    {`₹${microfinanceList.reduce(
                      (sum, m) => sum + m.total_loan,
                      0
                    )}`}
                  </Text>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col items-start justify-start sm:ml-[0] p-[25px] sm:px-5 rounded-[25px] w-full">
              <div className="flex flex-row gap-[15px] items-center justify-start w-[65%] md:w-full">
                <Button
                  className="flex h-[70px] items-center justify-center rounded-[50%] w-[70px]"
                  shape="circle"
                  color="gray_102"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    className="h-[30px]"
                    src="images/img_reply.svg"
                    alt="reply"
                    style={{
                      filter: "brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(405%) hue-rotate(83deg) brightness(92%) contrast(81%)"
                    }}
                  />
                </Button>
                <div className="flex flex-col gap-2 items-start justify-start">
                  <Text
                    className="text-base text-bluegray-400"
                    size="txtInterRegular16Bluegray400"
                  >
                    General Insights ({generalfinanceList.length})
                  </Text>
                  <Text
                    className="text-indigo-600 text-xl"
                    size="txtInterSemiBold20"
                  >
                    {`₹${generalfinanceList.reduce(
                      (sum, g) => sum + g.total_loan,
                      0
                    )}`}
                  </Text>
                </div>
              </div>
            </div>
          </List>
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-full">
            <div className="flex md:flex-1 flex-col gap-[18px] items-start justify-start w-[58%] md:w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Finance Insights
              </Text>
              <List
                className="flex flex-col gap-[15px] items-center w-full"
                orientation="vertical"
              >
                {detailInsightsCard(Constants.totalTitles, {
                  borrowedAmount:
                    chitsList.reduce((sum, c) => sum + c.chit_amount, 0) +
                    microfinanceList.reduce((sum, c) => sum + c.total_loan, 0) +
                    generalfinanceList.reduce(
                      (sum, c) => sum + c.total_loan,
                      0
                    ),
                  loanPaidAmount:
                    chitsList.reduce(
                      (sum, c) => sum + (c.chit_amount * c.commission) / 100,
                      0
                    ) +
                    microfinanceList.reduce(
                      (sum, c) =>
                        sum + (c.total_loan - c.issued_amount) / c.duration,
                      0
                    ) +
                    generalfinanceList.reduce(
                      (sum, c) => sum + (c.total_loan * c.interest) / 100,
                      0
                    ),
                  balanceAmount: 55000,
                })}
                {detailInsightsCard(Constants.chitsTitles, {
                  borrowedAmount: chitsList.reduce(
                    (sum, c) => sum + c.chit_amount,
                    0
                  ),
                  loanPaidAmount: chitsList.reduce(
                    (sum, c) => sum + (c.chit_amount * c.commission) / 100,
                    0
                  ),
                  balanceAmount: 100,
                })}
                {detailInsightsCard(Constants.microFinanceTitles, {
                  borrowedAmount: microfinanceList.reduce(
                    (sum, c) => sum + c.total_loan,
                    0
                  ),
                  loanPaidAmount: microfinanceList.reduce(
                    (sum, c) =>
                      sum + (c.total_loan - c.issued_amount) / c.duration,
                    0
                  ),
                  balanceAmount: 55000,
                })}
                {detailInsightsCard(Constants.generalFinanceTitles, {
                  borrowedAmount: generalfinanceList.reduce(
                    (sum, c) => sum + c.total_loan,
                    0
                  ),
                  loanPaidAmount: generalfinanceList.reduce(
                    (sum, c) => sum + (c.total_loan * c.interest) / 100,
                    0
                  ),
                  balanceAmount: 1000,
                })}
              </List>
            </div>
            <div className="flex md:flex-1 flex-col gap-[18px] items-start justify-start w-[41%] md:w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Recent Transactions
              </Text>
              <List
                className="bg-white-A700 flex flex-col gap-[21px] items-center p-[25px] sm:px-5 rounded-[25px] w-full h-[300px] overflow-auto"
                orientation="vertical"
              >
                {payments.length > 0 ? (
                  payments.map((v, i) => {
                    return recentTransactionCard(v);
                  })
                ) : (
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    No Recent Transactions
                  </Text>
                )}
              </List>
            </div>
          </div>
          {/* <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-full">
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[66%] md:w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Add Customer
              </Text>
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full">
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full"
                  size="txtInterRegular16Bluegray400"
                >
                  Enter required information for the customer in below fields.{" "}
                  <br></br>All fields are required
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      First Name
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Last Name
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Mobile Number
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      type="password"
                      shape="round"
                      color="white_A700"
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Address
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                    ></Input>
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                >
                  Add Customer
                </Button>
              </div>
            </div>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start w-[32%] md:w-full">
              <Text
                className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                size="txtInterSemiBold22"
              >
                Explore
              </Text>
              <List
                className="bg-white-A700 flex flex-col gap-5 items-center p-[30px] sm:px-5 rounded-[25px] w-full"
                orientation="vertical"
              >
                <div className="flex md:flex-1 flex-row gap-5 items-center justify-start w-[100%] md:w-full">
                  <Button
                    className="flex h-[60px] items-center justify-center w-[60px]"
                    shape="round"
                    color="gray_102"
                    size="md"
                    variant="fill"
                  >
                    <Img
                      className="h-[25px]"
                      src="images/img_videocamera_60X60.svg"
                      alt="videocamera"
                    />
                  </Button>
                  <div className="flex flex-col gap-[7px] items-start justify-start">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterMedium16Bluegray900"
                    >
                      Penalty
                    </Text>
                    <Text
                      className="text-[15px] text-bluegray-400"
                      size="txtInterRegular15Bluegray400"
                    >
                      Penalize customers
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-1 flex-row gap-5 items-center justify-start w-[100%] md:w-full">
                  <Button
                    className="flex h-[60px] items-center justify-center w-[60px]"
                    shape="round"
                    color="gray_102"
                    size="md"
                    variant="fill"
                  >
                    <Img
                      className="h-[25px]"
                      src="images/img_lock.svg"
                      alt="lock"
                    />
                  </Button>
                  <div className="flex flex-col gap-[7px] items-start justify-start">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterMedium16Bluegray900"
                    >
                      Borrowings
                    </Text>
                    <Text
                      className="text-[15px] text-bluegray-400"
                      size="txtInterRegular15Bluegray400"
                    >
                      Borrowings from lendors
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-1 flex-row gap-5 items-center justify-start w-[100%] md:w-full">
                  <Button
                    className="flex h-[60px] items-center justify-center w-[60px]"
                    shape="round"
                    color="gray_102"
                    size="md"
                    variant="fill"
                  >
                    <Img
                      className="h-[25px]"
                      src="images/img_user_70X70.svg"
                      alt="google"
                    />
                  </Button>
                  <div className="flex flex-col gap-[7px] items-start justify-start">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterMedium16Bluegray900"
                    >
                      Users
                    </Text>
                    <Text
                      className="text-[15px] text-bluegray-400"
                      size="txtInterRegular15Bluegray400"
                    >
                      Portal users
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-1 flex-row gap-5 items-center justify-start w-[100%] md:w-full">
                  <Button
                    className="flex h-[60px] items-center justify-center w-[60px]"
                    shape="round"
                    color="gray_102"
                    size="md"
                    variant="fill"
                  >
                    <Img
                      className="h-[25px]"
                      src="images/img_reply.svg"
                      alt="maximize"
                    />
                  </Button>
                  <div className="flex flex-col gap-[7px] items-start justify-start">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterMedium16Bluegray900"
                    >
                      Chat
                    </Text>
                    <Text
                      className="text-[15px] text-bluegray-400"
                      size="txtInterRegular15Bluegray400"
                    >
                      Send reminder message to a customer
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-1 flex-row gap-5 items-center justify-start w-[100%] md:w-full">
                  <Button
                    className="flex h-[60px] items-center justify-center w-[60px]"
                    shape="round"
                    color="gray_102"
                    size="md"
                    variant="fill"
                  >
                    <Img
                      className="h-[25px]"
                      src="images/img_lock.svg"
                      alt="maximize One"
                    />
                  </Button>
                  <div className="flex flex-col gap-[7px] items-start justify-start">
                    <Text
                      className="text-base text-bluegray-900"
                      size="txtInterMedium16Bluegray900"
                    >
                      Payments
                    </Text>
                    <Text
                      className="text-[15px] text-bluegray-400"
                      size="txtInterRegular15Bluegray400"
                    >
                      Analyze payments
                    </Text>
                  </div>
                </div>
              </List>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MainDashboardPage;
