import React, { useRef } from "react";
import { IoMdClose } from "react-icons/io";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  SelectBox,
  Switch,
  Text,
} from "components";
import { ICustomer } from "interfaces/customer";
import { ApiProvider } from "apiProvider/api_provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FinanceStatus,
  IMFAmounts,
  IMFPayment,
  IMicroFinance,
  durationTypeList,
} from "interfaces/micro_finance_interfaces";
import { Constants } from "constants/constants";
import Pagination from "pagination/pagination";
import { CloseSVG } from "assets/images";
import { IGlobalProps } from "Routes";
import { Permission, PermissionClass } from "interfaces/user_interfaces";
import { blocked } from "pages/Customers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import numWords from "num-words";

const MicroFinance = (props: IGlobalProps) => {
  const pdfRef = useRef();
  const [refresh, setRefresh] = React.useState(true);
  const [paymentsPopup, setPaymentsPopup] = React.useState(false);
  const [addPopup, setAddPopup] = React.useState(false);

  const [mfAmount, setmfAmount] = React.useState<string>("");
  const [mfCustomer, setmfCustomer] = React.useState<string>("");
  const [mfIssuedAmount, setmfIssuedAmount] = React.useState<string>("");
  const [mfDuration, setmfDuration] = React.useState<string>("");
  const [mfDurationType, setmfDurationType] = React.useState<string>("");
  const [mfStartDate, setmfStartDate] = React.useState<string>("");
  const [mfDueDate, setmfDueDate] = React.useState<string>("");

  const [customerList, setCustomerList] = React.useState<ICustomer[]>(null);
  const [dropdownCustomerList, setDropdownCustomerList] = React.useState([]);

  const [microfinanceList, setMicrofinanceList] = React.useState<
    IMicroFinance[]
  >([]);

  const [selectedIndex, setSelectedIndex] = React.useState<number>(null);

  const [page, setPage] = React.useState<number>(1);
  const [searchText, setSearchText] = React.useState<string>("");
  const [active, setActive] = React.useState<boolean>(true);

  const [totalCount, setTotalCount] = React.useState<number>(null);

  const fetchCustomers = async () => {
    ApiProvider.getAllCustomers(`api/fetch-all-customer?search=`).then(
      (response) => {
        setCustomerList(response);
        let data = response.map((v, i) => {
          return {
            label: v.firstName + " " + v.lastName,
            value: JSON.stringify(v),
          };
        });
        setDropdownCustomerList(data);
      }
    );
  };

  const downloadPDF = async () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("micro-finance.pdf");
    });
  };

  const addMicrofinance = async () => {
    if (
      mfAmount == "" ||
      mfCustomer == "" ||
      mfIssuedAmount == "" ||
      mfDueDate == "" ||
      mfDuration == "" ||
      mfStartDate == "" ||
      mfDurationType == ""
    ) {
      toast("Fill all fields!");
      return;
    }

    if (parseInt(mfIssuedAmount) > parseInt(mfAmount)) {
      toast("Issued amount cannot be greater than principal amount!");
      return;
    }

    let arr = new Array(parseInt(mfDuration));
    arr.fill(0);

    let details = {
      customer: mfCustomer,
      total_loan: parseInt(mfAmount),
      issued_amount: parseInt(mfIssuedAmount),
      duration: parseInt(mfDuration),
      duration_type: mfDurationType,
      payments: JSON.stringify(getPayments(arr)),
      receiving_amounts: JSON.stringify(getReceivingAmounts(arr)),
      start_date: mfStartDate,
      due_date: mfDueDate,
      status: FinanceStatus.ONGOING,
    };

    ApiProvider.addMicrofinance(JSON.stringify(details)).then((res) => {
      if (res == true) {
        setAddPopup(false);
        clearFields();
        toast("Microfinance added successfully");
        setRefresh(!refresh);
      } else {
        toast("Microfinance not added");
      }
    });
  };

  const clearFields = () => {
    setmfAmount("");
    setmfIssuedAmount("");
    setmfCustomer("");
    setmfDueDate("");
    setmfDuration("");
    setmfDurationType("");
    setmfStartDate("");
  };

  const getPayments = (arr: Array<number>): IMFPayment[] => {
    let paymentList: IMFPayment[] = arr.map((v, i) => {
      return {
        amount: 0,
        received_date: "",
      };
    });
    return paymentList;
  };

  const getReceivingAmounts = (arr: Array<number>): IMFAmounts[] => {
    let receivingAmount: number = parseFloat(
      (parseInt(mfAmount) / parseInt(mfDuration)).toFixed(2)
    );
    let amountList: IMFAmounts[] = arr.map((v, i) => {
      return {
        amount: receivingAmount,
      };
    });
    return amountList;
  };

  const completeMicroFinance = async () => {
    if (selectedIndex == null) {
      toast("Please try later!");
      return;
    }

    if (
      window.confirm(
        `Mark ${microfinanceList[selectedIndex].mf_id} as complete`
      )
    ) {
      let data = {
        status: FinanceStatus.COMPLETED,
      };

      ApiProvider.updateMicroFinance(
        JSON.stringify(data),
        microfinanceList[selectedIndex].id
      ).then((res) => {
        if (res == true) {
          setSelectedIndex(null);
          toast(
            `${microfinanceList[selectedIndex].mf_id} is marked as complete`
          );
          setRefresh(!refresh);
        } else {
          toast(
            `${microfinanceList[selectedIndex].mf_id}'s completion marking failed. Please try again later!`
          );
        }
      });
    }
  };

  const fetchMicrofinances = () => {
    ApiProvider.getMicroFinances(
      `api/get-microfinance?status=${
        active == true ? FinanceStatus.ONGOING : FinanceStatus.COMPLETED
      }&page=${page}&size=10&search=${searchText}`
    ).then((res) => {
      setTotalCount(Math.ceil(res.total / 10));
      setMicrofinanceList(res.mf);
    });
  };

  React.useEffect(() => {
    fetchCustomers();
  }, [refresh]);

  React.useEffect(() => {
    fetchMicrofinances();
  }, [refresh, active, page, searchText]);

  // const customerListItem = (
  //   border: boolean,
  //   data: IMicroFinance,
  //   index: number
  // ) => (
  //   <div className="flex flex-col gap-3.5 items-center justify-start w-full">
  //     <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start rounded-[17.5px] w-[99%] md:w-full">
  //       <Text
  //         className="text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {data.mf_id}
  //       </Text>
  //       <div className="flex flex-col items-start justify-start w-[20%] md:ml-[0] ml-[20px]">
  //         <Text
  //           className="text-base text-bluegray-900"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           {data.customer.firstName + " " + data.customer.lastName}
  //         </Text>
  //         <Text
  //           className="text-base text-bluegray-400"
  //           size="txtInterRegular15Bluegray400"
  //         >
  //           {data.customer.phoneNumber}
  //         </Text>
  //       </div>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.total_loan}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.issued_amount}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.total_loan -
  //           data.payments.reduce((partialSum, a) => partialSum + a.amount, 0)
  //           }`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`₹${data.payments.reduce(
  //           (partialSum, a) => partialSum + a.amount,
  //           0
  //         )}`}
  //       </Text>
  //       <Text
  //         className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[10%]"
  //         size="txtInterRegular16Bluegray900"
  //       >
  //         {`${data.duration} ${data.duration_type}`}
  //       </Text>
  //       <Button
  //         className="cursor-pointer font-medium sm:w-100 md:w-100 lg:w-[10%] leading-[normal] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //         color="indigo_500"
  //         size="xs"
  //         variant="outline"
  //         onClick={() => {
  //           setSelectedIndex(index);
  //           setPaymentsPopup(true);
  //         }}
  //       >
  //         View
  //       </Button>
  //       {data.status == FinanceStatus.ONGOING ? (
  //         PermissionClass.hasPermission(Permission.MFWRITE) ? (
  //           <Button
  //             className="cursor-pointer font-medium sm:w-100 md:w-100 lg:w-[15%] leading-[normal] md:ml-[0] ml-[20px] rounded-[17.5px] text-[15px] text-center"
  //             color="indigo_500"
  //             size="xs"
  //             variant="outline"
  //             onClick={() => {
  //               setSelectedIndex(index);
  //               completeMicroFinance();
  //             }}
  //           >
  //             Mark Complete
  //           </Button>
  //         ) : (
  //           blocked()
  //         )
  //       ) : (
  //         <Text
  //           className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
  //           size="txtInterRegular16Bluegray900"
  //         >
  //           Completed
  //         </Text>
  //       )}
  //     </div>
  //     {border && <Line className="bg-gray-105 h-px w-full" />}
  //   </div>
  // );
  const customerListItem = (
    border: boolean,
    data: IMicroFinance,
    index: number
  ) => (
    <tr
      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
      key={index}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 dark:text-white"
      >
        {" "}
        {data.mf_id}
      </th>
      <td className="px-6 py-4">{Constants.formatDate(data.start_date)}</td>
      <td className="px-6 py-4">
        {data.customer.firstName + " " + data.customer.lastName}
        <br />
        {data.customer.phoneNumber}
      </td>
      <td className="px-6 py-4">{`₹${data.total_loan}`}</td>
      <td className="px-6 py-4">{`₹${data.issued_amount}`}</td>
      <td className="px-6 py-4">
        {`₹${
          data.total_loan -
          data.payments.reduce((partialSum, a) => partialSum + a.amount, 0)
        }`}
      </td>
      <td className="px-6 py-4">{`₹${data.payments.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      )}`}</td>
      <td className="px-6 py-4">{`${data.duration} ${data.duration_type}`}</td>
      <td className="px-6 py-4">
        <Button
          className="cursor-pointer font-medium rounded-[17.5px] text-[15px] text-center"
          color="indigo_500"
          size="xs"
          variant="outline"
          onClick={() => {
            setSelectedIndex(index);
            setPaymentsPopup(true);
          }}
        >
          View
        </Button>
      </td>
      <td className="px-6 py-4">
        {data.status == FinanceStatus.ONGOING ? (
          PermissionClass.hasPermission(Permission.MFWRITE) ? (
            <Button
              className="cursor-pointer font-medium rounded-[17.5px] text-[15px] text-center"
              color="indigo_500"
              size="xs"
              variant="outline"
              onClick={() => {
                setSelectedIndex(index);
                completeMicroFinance();
              }}
            >
              Mark Complete
            </Button>
          ) : (
            blocked()
          )
        ) : (
          <Text
            className="md:ml-[0] ml-[20px] text-base text-bluegray-900 w-[15%]"
            size="txtInterRegular16Bluegray900"
          >
            Completed
          </Text>
        )}
      </td>
      <td className="px-6 py-4">
        {PermissionClass.hasPermission(Permission.ADMIN) && (
          <img
            className="flex h-[30px] items-center justify-center rounded-[50%] w-[30px]"
            src="images/bin.png"
            alt="user One"
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this Microfinance?")
              ) {
                ApiProvider.delete(`api/delete-microfinance/${data.id}`).then(
                  (v) => {
                    if (v == true) {
                      toast("Micro Finance Deleted");
                      window.location.reload();
                    } else {
                      toast("Micro finance not deleted! Try again later..");
                    }
                  }
                );
              }
            }}
          />
        )}
      </td>
    </tr>
  );

  return (
    <>
      <ToastContainer />
      <div className="flex flex-1 flex-col gap-[25px] items-center justify-start md:px-5 sm__w_mobile_100 w-[70%] sm_w_dynamic_100">
        <div className="bg-white-A700 border-b border-gray-300 border-solid flex md:flex-col flex-row md:gap-5 items-center justify-between p-5 w-full sm:h-[100%] md:h-[100%] h-[80px]">
          <div className="flex flex-row items-center whitespace-nowrap">
            {props.isSideBarOpen == false && (
              <Img
                className="h-10 md:h-auto object-cover w-[50px] cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="Logo"
                onClick={() => {
                  props.setIsSideBarOpen(true);
                }}
                style={{
                  filter: Constants.imgFilter,
                }}
              />
            )}
            <Text
              className="md:ml-[0] sm:text-2xl md:text-[26px] text-[28px] text-gray-900 pr-[24px]"
              size="txtInterSemiBold28"
            >
              Micro Finance
            </Text>
            <Switch
              onColor="#359766"
              offColor="#e3e4e8"
              onHandleColor="#ffffff"
              offHandleColor="#ffffff"
              value={active}
              className="w-[15%]"
              onChange={(v) => {
                setPage(1);
                setActive(v);
              }}
            />
          </div>
          <div className="flex flex-row items-center justify-end">
            <Input
              name="GroupEight"
              placeholder="Search for customers"
              value={searchText}
              onChange={(e) => setSearchText(e)}
              className="!placeholder:text-bluegray-400 !text-bluegray-400 leading-[normal] p-0 text-[15px] text-left w-[100%]"
              wrapClassName="flex md:flex-1 md:ml-[0] md:mt-0 my-[5px] rounded-[25px] w-[100%] md:w-full"
              prefix={
                <Img
                  className="cursor-pointer h-5 ml-[25px] mr-[16px] my-[1px]"
                  src="images/img_search.svg"
                  alt="search"
                  style={{
                    filter: Constants.imgFilter,
                  }}
                />
              }
              suffix={
                <CloseSVG
                  fillColor="#888ea2"
                  className="cursor-pointer h-5 my-auto"
                  onClick={() => setSearchText("")}
                  style={{
                    visibility: searchText?.length <= 0 ? "hidden" : "visible",
                  }}
                  height={20}
                  width={20}
                  viewBox="0 0 20 20"
                />
              }
              color="gray_101"
            ></Input>
            <Button
              className="flex h-[50px] items-center justify-center rounded-[50%] w-[60px] ml-[20px]"
              shape="circle"
              color="gray_102"
              size="xs"
              variant="fill"
              onClick={downloadPDF}
            >
              <img
                className="h-[30px]"
                src="images/pdf_export.png"
                alt="PDF Export"
                style={{
                  filter: Constants.imgFilter,
                }}
              />
            </Button>
            {PermissionClass.hasPermission(Permission.MFWRITE) && (
              <Button
                className="flex h-[50px] items-center justify-center rounded-[50%] w-[60px] ml-[20px]"
                shape="circle"
                color="gray_102"
                size="xs"
                variant="fill"
                onClick={() => setAddPopup(true)}
              >
                <Img
                  className="h-[30px]"
                  src="images/img_add.png"
                  alt="user One"
                  style={{
                    filter: Constants.imgFilter,
                  }}
                />
              </Button>
            )}
          </div>
        </div>
        <div
          className="flex flex-col gap-6 items-center justify-start w-[98%] md:w-full"
          ref={pdfRef}
        >
          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
            <Text
              className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
              size="txtInterSemiBold22"
            >
              Overview
            </Text>
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-end p-[21px] sm:px-5 rounded-[25px] w-full">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        ID
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Started
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Customer
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Total Amount
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Issued Amount
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Balance
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Amount Paid
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Duration
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Emi Details
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      {PermissionClass.hasPermission(Permission.ADMIN) && <th scope="col" className="px-6 py-3">
                        Delete
                      </th>}
                    </tr>
                  </thead>
                  <tbody>
                    {microfinanceList &&
                      microfinanceList.map((e, i) =>
                        customerListItem(i != microfinanceList.length - 1, e, i)
                      )}
                  </tbody>
                </table>
              </div>
              {/* <List
                className="flex sm:overflow-x-scroll md:overflow-x-scroll flex-col gap-[15px] items-center md:ml-[0] ml-[9px] w-[99%]"
                orientation="vertical"
              >
                <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                  <div className="flex sm:flex-row md:flex-row lg:flex-col flex-row sm:gap-10 md:gap-10 lg:gap-5 items-center justify-start w-[99%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      ID
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[20%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Customer
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Total Amount
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Issued Amount
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Balance
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Amount Paid
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Duration
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[10%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Emi Details
                    </Text>
                    <Text
                      className="md:ml-[0] ml-[20px] text-base text-bluegray-400 w-[15%]"
                      size="txtInterMedium16Bluegray400"
                    >
                      Status
                    </Text>
                  </div>
                  <Line className="bg-gray-103 h-px w-full" />
                </div>
                {microfinanceList &&
                  microfinanceList.map((e, i) =>
                    customerListItem(i != microfinanceList.length - 1, e, i)
                  )}
              </List> */}
            </div>
          </div>
        </div>
        {searchText == "" && totalCount != null && totalCount > 1 && (
          <div className="flex flex-row items-center w-[30%] md:w-full">
            <div
              className="flex flex-row items-center justify-evenly w-[28%] cursor-pointer"
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Img
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
                style={{
                  filter: Constants.imgFilter,
                }}
              />
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Previous
              </Text>
            </div>
            <Button
              className="cursor-pointer font-medium h-10 leading-[normal] ml-3 rounded-[10px] text-[15px] text-center w-10"
              color="indigo_600"
              size="sm"
              variant="fill"
            >
              {page}
            </Button>
            <div
              className="flex flex-row items-center justify-evenly ml-3 w-[25%] cursor-pointer"
              onClick={() => {
                if (page < totalCount) {
                  setPage(page + 1);
                  window.scrollTo(0, 0);
                }
              }}
            >
              <Text
                className="text-[15px] text-indigo-600"
                size="txtInterMedium15"
              >
                Next
              </Text>
              <Img
                className="cursor-pointer h-6 w-6"
                src="images/img_arrowright.svg"
                alt="arrowright"
                style={{
                  filter: Constants.imgFilter,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto">
          <form onSubmit={(e) => {}}>
            <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
              <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                  <Text
                    className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                    size="txtInterSemiBold22"
                  >
                    Add Microfinance
                  </Text>
                  <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                    <IoMdClose
                      onClick={() => {
                        setAddPopup(false);
                      }}
                    />
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal"
                  size="txtInterRegular16Bluegray400"
                >
                  To create a micro finance provide data for all the fields.
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Total Amount
                    </Text>
                    <Input
                      name="Group195"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={mfAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setmfAmount(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Issued Amount
                    </Text>
                    <Input
                      name="Group195 One"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={mfIssuedAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setmfIssuedAmount(e);
                        }
                      }}
                    ></Input>
                  </div>
                </div>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Total amount in words ={" "}
                  {`${Constants.capitalizeFirstLetter(
                    numWords(parseInt(mfAmount))
                  )}`}
                </Text>
                <Text
                  className="leading-[28.00px] md:ml-[0] ml-[3px] text-base text-bluegray-400 w-[94%] sm:w-full whitespace-normal pt-6"
                  size="txtInterRegular16Bluegray400"
                >
                  Issued amount in words ={" "}
                  {`${Constants.capitalizeFirstLetter(
                    numWords(parseInt(mfIssuedAmount))
                  )}`}
                </Text>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Duration
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      value={mfDuration}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e === "" || re.test(e)) {
                          setmfDuration(e);
                        }
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Duration Type
                    </Text>
                    <SelectBox
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: Constants.imgFilter,
                          }}
                        />
                      }
                      isMulti={false}
                      options={durationTypeList}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        setmfDurationType(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Start Date
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      type="date"
                      value={mfStartDate}
                      onChange={(e) => {
                        setmfStartDate(e);
                      }}
                    ></Input>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Payment Due Date
                    </Text>
                    <Input
                      name="Group195 Two"
                      className="leading-[normal] p-0 placeholder:text-bluegray-900 text-[15px] text-left w-full"
                      wrapClassName="border border-gray-300 border-solid w-full"
                      shape="round"
                      color="white_A700"
                      type="date"
                      value={mfDueDate}
                      onChange={(e) => {
                        setmfDueDate(e);
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[29px] rounded-[15px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start rounded-[15px] w-[48%] md:w-full">
                    <Text
                      className="text-base text-bluegray-400"
                      size="txtInterRegular16Bluegray400"
                    >
                      Customer
                    </Text>
                    <SelectBox
                      menuPlacement="top"
                      className="border border-gray-300 border-solid leading-[normal] text-[15px] text-left w-full"
                      placeholderClassName="text-bluegray-900"
                      indicator={
                        <Img
                          className="h-6 mr-[19px] w-6"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          style={{
                            filter: Constants.imgFilter,
                          }}
                        />
                      }
                      isMulti={false}
                      options={dropdownCustomerList}
                      isSearchable={true}
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                      onChange={(value) => {
                        let customer: ICustomer = JSON.parse(value);
                        setmfCustomer(customer.id);
                      }}
                    />
                  </div>
                </div>
                <Button
                  className="cursor-pointer font-medium leading-[normal] mb-[11px] min-w-[160px] md:ml-[0] ml-[3px] mt-[30px] rounded-[9px] text-center text-lg"
                  color="indigo_600"
                  size="md"
                  variant="fill"
                  type="button"
                  onClick={addMicrofinance}
                >
                  Add Microfinance
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {microfinanceList.length > 0 && selectedIndex != null && (
        <section
          style={{ background: "rgba(0,0,0,0.4)" }}
          className={
            paymentsPopup
              ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
              : "hidden"
          }
        >
          <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between max-w-[60vw] mx-auto rounded-lg max-h-[90vh] overflow-auto mobile__w_100_emi__payments">
            <form onSubmit={(e) => {}}>
              <div className="flex md:flex-1 flex-col gap-5 items-start justify-start">
                <div className="bg-white-A700 flex flex-col items-start justify-start p-[27px] sm:px-5 rounded-[25px] w-full overflow-auto">
                  <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <Text
                      className="text-[22px] text-bluegray-900 sm:text-lg md:text-xl"
                      size="txtInterSemiBold22"
                    >
                      EMI and Payments Overview
                    </Text>
                    <div className="py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
                      <IoMdClose
                        onClick={() => {
                          setPaymentsPopup(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[100%]">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Emi Amount
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Payment Received
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Emi Due Date
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Payment Received Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {microfinanceList[selectedIndex].payments.map(
                          (v, i) => {
                            return (
                              <tr
                                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                key={i}
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >{`₹${microfinanceList[selectedIndex].receiving_amounts[i].amount}`}</th>
                                <td className="px-6 py-4">{`₹${v.amount}`}</td>
                                <td className="px-6 py-4">
                                  {Constants.formatDate(
                                    Constants.addToDates(
                                      microfinanceList[selectedIndex]
                                        .duration_type,
                                      microfinanceList[selectedIndex].due_date,
                                      i
                                    )
                                  )}
                                </td>
                                <td className="px-6 py-4">
                                  {v.received_date != ""
                                    ? Constants.formatDate(v.received_date)
                                    : "-"}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-[48vw]">
                    <Text
                      className="text-base text-bluegray-400 w-[20%]"
                      size="txtInterRegular16Bluegray400"
                    >
                      Emi Amount
                    </Text>
                    <Text
                      className="text-base text-bluegray-400 w-[25%]"
                      size="txtInterRegular16Bluegray400"
                    >
                      Payment Received
                    </Text>
                    <Text
                      className="text-base text-bluegray-400 w-[25%]"
                      size="txtInterRegular16Bluegray400"
                    >
                      Emi Due Date
                    </Text>
                    <Text
                      className="text-base text-bluegray-400 w-[30%]"
                      size="txtInterRegular16Bluegray400"
                    >
                      Payment Received Date
                    </Text>
                  </div>
                  {microfinanceList[selectedIndex].payments.map((v, i) => {
                    return (
                      <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between md:ml-[0] ml-[3px] mt-[22px] w-[48vw]">
                        <Text
                          className="text-base text-bluegray-900 w-[20%]"
                          size="txtInterRegular16Bluegray900"
                        >
                          {`₹${microfinanceList[selectedIndex].receiving_amounts[i].amount}`}
                        </Text>
                        <Text
                          className="text-base text-bluegray-900 w-[25%]"
                          size="txtInterRegular16Bluegray900"
                        >
                          {`₹${v.amount}`}
                        </Text>
                        <Text
                          className="text-base text-bluegray-900 w-[25%]"
                          size="txtInterRegular16Bluegray900"
                        >
                          {Constants.formatDate(
                            Constants.addToDates(
                              microfinanceList[selectedIndex].duration_type,
                              microfinanceList[selectedIndex].due_date,
                              i
                            )
                          )}
                        </Text>
                        <Text
                          className="text-base text-bluegray-900 w-[30%]"
                          size="txtInterRegular16Bluegray900"
                        >
                          {v.received_date != ""
                            ? Constants.formatDate(v.received_date)
                            : "-"}
                        </Text>
                      </div>
                    );
                  })} */}
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default MicroFinance;
