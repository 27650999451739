import { ICustomer } from "./customer";

export interface IMicroFinanceAPIResponse {
  total : number;
  message : string;
  mf : IMicroFinance[];
}

export interface IMicroFinance {
  id?: string;
  mf_id?: string;
  customer: ICustomer;
  total_loan: number;
  issued_amount: number;
  duration: number;
  duration_type: DurationType;
  payments: IMFPayment[];
  receiving_amounts: IMFAmounts[];
  start_date: string;
  due_date: string;
  status: FinanceStatus;
}

export interface IMFPayment {
  amount: number;
  received_date: string;
}

export interface IMFAmounts {
  amount: number;
}

export enum FinanceStatus {
  ONGOING = "ongoing",
  COMPLETED = "completed",
}

export enum DurationType {
  MONTHS = "Months",
  WEEKS = "Weeks",
  DAYS = "Days",
}

export const durationTypeList = [ 
  {label : "Months" , value : DurationType.MONTHS},
  {label : "Weeks" , value : DurationType.WEEKS},
  {label : "Days" , value : DurationType.DAYS}
];
