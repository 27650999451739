import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import ChitFinance from "pages/ChitFinance";
import MicroFinance from "pages/MicroFinance";
import GeneralFinance from "pages/GeneralFinance";
import Customers from "pages/Customers";
import Users from "pages/Users";
import Payments from "pages/Payments";
import Penalty from "pages/Penalty";
import Borrowings from "pages/Borrowings";
import Auctions from "pages/Auctions/auctions";
import Signin from "pages/SignIn";
import MainDashboard from "pages/MainDashboard";
import SettingPageSecurity from "pages/SettingPageSecurity";

export interface IGlobalProps {
  isSideBarOpen : boolean;
  setIsSideBarOpen : (v : boolean) => void;
}

const ProjectRoutes = (props : IGlobalProps) => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/dashboard" element={<MainDashboard isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/chit_finance" element={<ChitFinance isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/auctions" element={<Auctions isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/micro_finance" element={<MicroFinance isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/general_finance" element={<GeneralFinance isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/customers" element={<Customers isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/users" element={<Users isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/payments" element={<Payments isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/penalty" element={<Penalty isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          <Route path="/borrowings" element={<Borrowings isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>} />
          {/* <Route path="/accounts" element={<Accounts />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/creditcards" element={<CreditCards />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/services" element={<Services />} />
          <Route path="/settingeditprofile" element={<SettingEditProfile />} />
          <Route
            path="/settingpagepreferences"
            element={<SettingPagePreferences />}
          /> */}
          <Route
            path="/support"
            element={<SettingPageSecurity isSideBarOpen={props.isSideBarOpen} setIsSideBarOpen={props.setIsSideBarOpen}/>}
          />
        </Routes>
    </React.Suspense>
  );
};
export default ProjectRoutes;
